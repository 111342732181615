import { login } from "@/api/login";
import { getToken, setToken, removeToken, setName, getName, removeName } from '@/utils/auth'
const user = {
  state: {
    token: getToken(),
    account: getName(),
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, account) => {
      state.account = account
    },
  },

  actions: {
    // 登录
    Login({ commit }, userInfo) {
      const account = userInfo.account.trim()
      return new Promise((resolve, reject) => {
        login({ account, accountPwd: userInfo.accountPwd }).then(res => {
          console.log(res, '888888');
          setToken(res.data.token)
          setName(res.data.name)
          commit('SET_TOKEN', res.data.token)
          commit('SET_NAME', res.data.name)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 前端 登出
    LogOut({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '')
        commit('SET_NAME', '')
        removeToken()
        removeName()
        resolve()
      })
    }
  }
}

export default user
