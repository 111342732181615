<template>
  <Layout>
    <!-- Start Slider Area -->
    <div
      class="demo-slider-area slider-area bg-transparent slider-style-1 pb--100 pt--70"
    >
      <div class="container1">
        <div class="row">
          <div class="col-lg-12">
            <!-- 公告 -->
            <div class="inner content-top">
              <div
                class="react-image mb--20 top-title text-center"
                data-aos="fade-up"
              >
                <img src="../assets/img/dykw.png" alt="大有可为" />
              </div>
              <div class="notice">
                <img src="../assets/img/gg.png" alt="" />重要通知、公告
              </div>
              <div class="cus-h">
                <div
                  class="plr--40 ptb--5 flex"
                  v-for="item in noticeList"
                  :key="item.id"
                  @click="lookNotice(item.id, item)"
                >
                  <a :href="item.outUrl" target="_blank">
                    {{ item.title }}
                  </a>
                  <div>{{ item.noticeDate }}</div>
                </div>
              </div>
            </div>
            <!-- 公告，新闻，企业，党建 -->
            <div class="inner content-top1 mt--30 flex">
              <div class="img-content">
                <img src="../assets/img/r.png" alt="左图" />
              </div>
              <div class="center-content">
                <ul class="nav nav-tabs tab-button flex" role="tablist">
                  <li
                    class="nav-item tabs__tab"
                    role="presentation"
                    v-for="(tabMenu, index) in tabData"
                    :key="index"
                  >
                    <button
                      class="text-capitalize text-center tabs-style"
                      :class="{ active: index === 0 }"
                      :id="`${tabMenu.id}`"
                      data-bs-toggle="tab"
                      :data-bs-target="`#${tabMenu.id}`"
                      type="button"
                      role="tab"
                      :aria-controls="tabMenu.id"
                      :aria-selected="index === activeTab ? 'true' : 'false'"
                      @click="activeTab = index"
                    >
                      {{ tabMenu.text }}
                    </button>
                  </li>
                </ul>
                <div
                  class="item-style ellipsis"
                  v-for="(content, index) in tabData[activeTab].dataList"
                  :key="index"
                  @click="lookNotice(content.id, content)"
                >
                  <a :href="content.outUrl" target="_blank">
                    {{ content.title || content.name }}
                  </a>
                </div>
              </div>
              <div class="img-content">
                <img src="../assets/img/l.png" alt="右图" />
              </div>
            </div>

            <!-- 经济地图 -->
            <div class="map-content mt--30 flex">
              <div class="left-map">
                <div
                  class="item-map"
                  v-for="(item, index) in statisticsList"
                  :key="index"
                >
                  <div>
                    <img
                      class="icon-img"
                      :src="require(`../assets/img/${img[index]}`)"
                      alt=""
                    />{{ item.companyType }}
                  </div>
                  <div class="flex">
                    <div class="line1"></div>
                    {{ item.companyNum }}家
                  </div>
                </div>
              </div>
              <div class="center-map">
                <!-- 龙泉驿区大面街道经济地图 -->
                <el-carousel
                  class="swiper"
                  style="width: 100%; height: 100%"
                  :interval="5000"
                  arrow="never"
                  autoplay
                >
                  <el-carousel-item key="1">
                    <img src="../assets/img/map1.jpg" alt="" />
                  </el-carousel-item>
                  <el-carousel-item key="2">
                    <img src="../assets/img/map2.jpg" alt="" />
                  </el-carousel-item>
                  <el-carousel-item key="3">
                    <img src="../assets/img/map3.jpg" alt="" />
                  </el-carousel-item>
                </el-carousel>
              </div>
              <div class="right-map">
                <div
                  class="mr"
                  v-for="(item, index) in serveDataList"
                  :key="index"
                >
                  <dv-active-ring-chart
                    style="width: 100px; height: 100px"
                    :config="{
                      ...config,
                      data: [{ value: item.problemNum, name: '' }],
                      color: [config.color[index]],
                    }"
                  />
                </div>
                <div
                  class="mr position"
                  :class="`position-${index}`"
                  v-for="(item, index) in serveDataList"
                  :key="index"
                >
                  <div style="width: 100px; height: 100px" class="flex-column">
                    <div class="pb-text">{{ item.problemNum }}</div>
                    <div>{{ item.problemType }}</div>
                  </div>
                </div>
              </div>
            </div>

            <!-- 企业风采 -->
            <div class="inner content-qy mt--30">
              <div class="react-image top-title text-center" data-aos="fade-up">
                <img src="../assets/img/qyfc.png" alt="企业风采" />
              </div>
              <el-tabs v-model="activeName" @tab-click="handleClick">
                <template v-for="(item, index) in companyTypeOptions">
                  <el-tab-pane
                    :key="index"
                    :label="item.dictLabel"
                    :name="item.dictValue"
                  >
                    <el-carousel
                      style="height: 344px"
                      :interval="5000"
                      arrow="never"
                      autoplay
                      type="card"
                      indicator-position="none"
                    >
                      <el-carousel-item
                        v-for="(items, indexs) in companyList"
                        :key="indexs"
                        style="height: 100%"
                      >
                        <div>
                          <div class="banner-h bg_image">
                            <div class="flex h100">
                              <a
                                class="right-qy"
                                :href="items.outUrl"
                                target="_blank"
                              >
                                <img :src="items.picUrl" alt="" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </el-carousel-item>
                    </el-carousel>
                  </el-tab-pane>
                </template>
              </el-tabs>
              <!-- bananer内容 -->
            </div>

            <!-- 企业服务 -->
            <div class="inner content-bottom mt--30">
              <div class="mb--40 top-title text-center" data-aos="fade-up">
                <img
                  src="../assets/img/dmcylm.png"
                  alt="大面铺子产业联盟企业服务"
                />
              </div>
              <!-- 服务内容 -->
              <div class="body-content flex">
                <router-link
                  :to="item.link"
                  class="items text-center"
                  v-for="(item, index) in serveList"
                  :key="index"
                  :style="{ 'background-color': item.color }"
                >
                  <img :src="require(`@/assets/img/${item.icon}`)" alt="" />
                  {{ item.label }}
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      width="50%"
      @close="dialogVisible = false"
    >
      <div class="text-center font-32">{{ noticeDetail.title }}</div>
      <div v-if="noticeDetail.content" v-html="noticeDetail.content"></div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">关闭</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title=""
      :visible.sync="dialogVisible2"
      width="50%"
      @close="dialogVisible2 = false"
    >
      <div class="text-center font-32">{{ newsDetail.title }}</div>
      <div v-if="newsDetail.content" v-html="newsDetail.content"></div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible2 = false">关闭</el-button>
      </span>
    </el-dialog>
  </Layout>
</template>

<script>
import Layout from "../components/common/Layout";

import {
  getNotice,
  getNoticeList,
  getNewList,
  getNew,
  getKeyCompanyList,
  getIndustrialStyleList,
  getCompanyList,
  getStatisticsList,
  getServeList,
  getCompanyInfoList,
} from "@/api/home";

export default {
  name: "Demo",
  components: { Layout },
  data() {
    return {
      activeTab: 0,
      dialogVisible2: false,
      noticeList: [],
      newsList: [],
      serveDataList: [], // 企业服务实时数据
      noticeDetail: {},
      newsDetail: {},
      dialogVisible: false,
      activeName: "0",
      tabData: [
        {
          id: "notice",
          text: "公告",
          dataList: [
            { description: "助企纾困政策｜服务业类（第七期）" },
            { description: "社区动态｜“帮忙代办”来跑腿 便民服务暖人心" },
            { description: "天热了，公众日常生活中如何正确使用医用口罩？" },
          ],
        },
        {
          id: "news",
          text: "新闻",
          dataList: [
            { description: "助企纾困政策｜服务业类（第七期）" },
            { description: "可持续性发展" },
            { description: "天热了，公众日常生活中如何正确使用医用口罩？" },
          ],
        },
        {
          id: "company",
          text: "企业",
          dataList: [
            { description: "助企纾困政策｜服务业类（第七期）" },
            { description: "引进先进企业" },
            { description: "天热了，公众日常生活中如何正确使用医用口罩？" },
          ],
        },
        {
          id: "party",
          text: "党建",
          dataList: [
            { title: "百年大计" },
            { title: "社区动态｜“帮忙代办”来跑腿 便民服务暖人心" },
            { title: "天热了，公众日常生活中如何正确使用医用口罩？" },
          ],
        },
      ],
      serveList: [
        {
          label: "项目申报",
          color: "#FDBB98",
          icon: "xmsb.png",
          link: "/projectApplication/product",
        },
        {
          label: "我要场地",
          color: "#4DE3AD",
          icon: "wycd.png",
          link: "/projectApplication/site",
        },
        {
          label: "我要人才",
          color: "#ACAEFF",
          icon: "wyrc.png",
          link: "/projectApplication/talent",
        },
        {
          label: "我要政策",
          color: "#FDB4FB",
          icon: "wybt.png",
          link: "/projectApplication/subsidies",
        },
        {
          label: "我要资金",
          color: "#F7C46C",
          icon: "wytz.png",
          link: "/projectApplication/investment",
        },
        {
          label: "我要贷款",
          color: "#B4F479",
          icon: "wydk.png",
          link: "/projectApplication/loans",
        },
        {
          label: "企业活动",
          color: "#DEB7FF",
          icon: "qyzc.png",
          link: "/projectApplication/active",
        },
        {
          label: "我要帮助",
          color: "#AFDBFF",
          icon: "wybz.png",
          link: "/projectApplication/help",
        },
        {
          label: "意见反馈",
          color: "#FDC2DF",
          icon: "yjfk.png",
          link: "/projectApplication/feedback",
        },
      ],
      companyList: [], // 公司
      statisticsList: [],

      img: [
        "ztdw.png",
        "jrcs.png",
        "wcwl.png",
        "smwl.png",
        "zdfwdx.png",
        "xjzzy.png",
      ],

      qylist: [],

      config: {
        color: ["#EF8644", "#4DE3AD", "#F3E256", "#5F60EE"],
        radius: 40,
        activeRadius: 40,
        lineWidth: 5,
        digitalFlopStyle: {
          fontSize: 16,
          fill: "#5E5FED",
        },
        showOriginValue: true,
      },

      companyOptions: [], // 企业类型字典
      companyTypeOptions: [],
    };
  },
  created() {
    this.notice();
    this.news();
    this.company();
    this.statistics();
    this.serveData();
    this.getListCompany();
    this.getDicts("company_statistics").then((response) => {
      this.companyOptions = response.data;
    });
    this.getDicts("business_company_type").then((response) => {
      this.companyTypeOptions = response.data;
    });
  },
  methods: {
    statistics() {
      getStatisticsList().then((res) => {
        if (res.code == 200) {
          this.statisticsList = res.rows;
        }
      });
    },

    // 字典回显
    formatterItem(type) {
      return this.selectDictLabel(this.companyOptions, type);
    },

    serveData() {
      getServeList().then((res) => {
        if (res.code == 200) {
          this.serveDataList = res.rows;
        }
      });
    },
    handleClick({ name }) {
      // 根据类型查询企业
      console.log(name);
      this.company(name);
    },
    company(key = "0") {
      getIndustrialStyleList({ keyFlag: key }).then((res) => {
        if (res.code == 200) {
          this.companyList = res.rows;
        }
      });
    },
    news() {
      getNewList().then((res) => {
        if (res.code == 200) {
          let init = res.rows;
          this.newsList = res.rows;
          if (res.rows.length > 3) {
            const arr = init.slice(0, 3);
            this.tabData[1].dataList = arr;
          } else {
            this.tabData[1].dataList = res.rows;
          }
        }
      });
    },
    notice() {
      getNoticeList().then((res) => {
        if (res.code == 200) {
          let init = res.rows;

          if (res.rows.length > 3) {
            const arr = init.slice(0, 3);
            this.tabData[0].dataList = arr;
          } else {
            this.tabData[0].dataList = res.rows;
          }
          this.noticeList = res.rows;
        }
      });
    },
    getListCompany() {
      getCompanyInfoList({ pageSize: 3, pageNum: 1 }).then((res) => {
        console.log(res, "企业预设信息");
        this.tabData[2].dataList = res.rows;
      });
    },
    lookNotice(id, item) {
      if (!item.outUrlFlag) {
        if (this.activeTab == 0) {
          getNotice(id).then((res) => {
            this.noticeDetail = res.data;
            this.dialogVisible = true;
          });
        }
        if (this.activeTab == 1) {
          getNew(id).then((res) => {
            this.newsDetail = res.data;
            this.dialogVisible2 = true;
          });
        }
      } else {
        return false;
      }
    },
    handleClose() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.container1 {
  width: 100%;
}
::v-deep .el-tabs__header {
  margin: 0px !important;
}
.content-top {
  width: 1085px;
  height: 276px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  margin: 0 auto;

  .top-title {
    width: 1085px;
    height: 80px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .notice {
    height: 38px;
    font-size: 20px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #323334;
    line-height: 28px;
    margin-left: 38px;
    border-bottom: 2px solid #f1f2f3;

    img {
      width: 24px;
      height: 24px;
      margin-right: 13px;
    }
  }
}

.cus-h {
  max-height: 125px;
  overflow-y: scroll;
}

.content-top1 {
  width: 1085px;
  height: 230px;
  margin: 0 auto;
}

.content-qy {
  width: 1085px;
  height: 474px;
  margin: 0 auto;
  background: #ffffff;

  .top-title {
    width: 1085px;
    height: 80px;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.content-bottom {
  width: 1085px;
  height: 762px;
  margin: 0 auto;
  background: #ffffff;

  .top-title {
    width: 1085px;
    height: 240px;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.img-content {
  width: 324px;
  height: 230px;
  background-color: #fff;

  img {
    width: 100%;
    height: 100%;
  }
}

.center-content {
  width: 420px;
  height: 230px;
  background: #ddf0ff;
  box-shadow: 0px 5px 10px 0px rgba(0, 53, 171, 0.2);
  border-radius: 4px 4px 0px 0px;

  button {
    border: none !important;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      bottom: 0px;
      left: 0px;
      width: 105px;
      height: 2px;
      background-color: #a0d0ff;
    }
  }
}

.tabs-style {
  width: 105px;
  height: 46px;
  background: transparent;
}
.mr {
  width: 100px;
  margin: 45px auto;
  margin-top: 40px;
}

.position {
  position: absolute;
  z-index: 88;

  color: #5e5fed;
}

.position-0 {
  top: 30px;
  left: 58px;
}
.position-1 {
  top: 175px;
  left: 58px;
}
.position-2 {
  top: 320px;
  left: 58px;
}
.position-3 {
  top: 465px;
  left: 58px;
}

.tabs-style.active,
.nav-tabs .tabs-style:focus {
  color: #fff;
  background: #5490cc;
  border: 0 none;
  box-shadow: none;
  border-radius: 4px 4px 0px 0px;
}

.item-style {
  width: 400px;
  line-height: 55px;
  border-bottom: 1px solid #d5e7f3;
  margin: 0 auto;
  cursor: pointer;
}
.ellipsis {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.map-content {
  width: 1085px;
  height: 650px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
}

.body-content {
  width: 100%;
  box-sizing: border-box;
  padding: 0 45px;
  flex-wrap: wrap;
}
.items {
  width: 308px;
  height: 112px;
  font-size: 22px;
  color: #ffffff;
  line-height: 112px;
  letter-spacing: 1px;
  margin-bottom: 42px;
  align-items: center;
  cursor: pointer;

  img {
    width: 24px;
    height: 24px;
  }

  &:hover {
    opacity: 0.8;
  }
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-start {
  display: flex;
  justify-content: flex-start;
}

.flex-column {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.pb-text {
  padding-bottom: 20px;
}
ul,
li {
  margin: 0 !important;
}
::v-deep .el-carousel__container {
  height: 100%;
}
.banner-h {
  height: 394px;
  width: 100%;
  padding: 30px;
}

.mtd-30 {
  // margin-top: 25px;
  margin-bottom: 25px;
}

.mr-30 {
  margin-right: 30px;
}

.left-qy {
  width: 60%;
}

.right-qy {
  width: 100%;
  margin: 0 auto;

  img {
    width: 100%;
    height: 300px;
  }
}

.title-item {
  width: 120px;
  height: 28px;
  background: #2577e4;
  color: #fff;
  position: relative;
  margin-left: 20px;
  text-align: center;

  &::before {
    content: "";
    position: absolute;
    width: 8px;
    height: 28px;
    background: #2577e3;
    left: -20px;
    top: 0;
  }

  &::after {
    content: "";
    position: absolute;
    width: 4px;
    height: 28px;
    background: #13a3eb;
    left: -8px;
    top: 0;
  }
}

.w-item {
  width: 400px;
}

.left-map {
  width: 218px;
  height: 620px;
  margin-top: 11px;
  background-image: url("../assets/img/left.png");
  background-size: 100% 100%;
}
.map-title {
  text-align: center;
  height: 71px;
  font-size: 36px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #000102;
  line-height: 71px;
  letter-spacing: 2px;
}
.center-map {
  width: 612px;
  height: 602px;
}

.center-map-bottom {
  width: 612px;
  height: 558px;
}

.right-map {
  width: 218px;
  height: 620px;
  margin-top: 11px;
  background-image: url("../assets/img/right.png");
  background-size: 100% 100%;
  position: relative;
}

.item-map {
  width: 186px;
  margin-left: 16px;
  margin-top: 45px;
}

.line1 {
  width: 124px;
  height: 4px;
  background: linear-gradient(90deg, #f37335 0%, #fdc830 100%);
  border-radius: 2px;
}

.line2 {
  width: 84px;
  height: 4px;
  background: linear-gradient(90deg, #1044cb 0%, #26e5db 100%);
  border-radius: 2px;
}

.line3 {
  width: 84px;
  height: 4px;
  background: linear-gradient(90deg, #3ca927 0%, #57e14b 100%);
  border-radius: 2px;
}

.line4 {
  width: 84px;
  height: 4px;
  background: linear-gradient(90deg, #f37335 0%, #fdc830 100%);
  border-radius: 2px;
}

.line5 {
  width: 84px;
  height: 4px;
  background: linear-gradient(90deg, #3ca927 0%, #57e14b 100%);
  border-radius: 2px;
}

.line6 {
  width: 84px;
  height: 4px;
  background: linear-gradient(90deg, #d306d6 0%, #ea61fc 100%);
  border-radius: 2px;
}

.swiper {
  img {
    width: 100%;
    height: 100%;
  }
}

.icon-img {
  width: 24px;
  height: 24px;
}

.font-32 {
  font-size: 28px;
  font-weight: 600;
  padding-bottom: 30px;
}
</style>
