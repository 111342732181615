import Vue from 'vue'
import App from './App.vue'
import Cookies from 'js-cookie'
import promise from 'es6-promise'
import router from './router'
import 'bootstrap/scss/bootstrap.scss'
import 'animate.css'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import './assets/scss/style.scss'
import 'bootstrap'
import CoolLightBox from 'vue-cool-lightbox'
import VueScrollActive from 'vue-scrollactive'
import Element from 'element-ui'



// import './assets/styles/element-variables.scss'
// import '@/assets/styles/variables.scss'
import './assets/styles/index.scss' // global css
// import './assets/styles/scbus.scss' // scbus css

import AOS from 'aos'
import 'aos/dist/aos.css'
import VueTypedJs from 'vue-typed-js'
import axios from 'axios'
import Pagination from "@/components/Pagination";

import store from './store'

// 将自动注册所有组件为全局组件
import dataV from '@jiaminghi/data-view'

import { getDicts } from "@/api/dict";

import { selectDictLabel, getFileDownloadPath, download, downloadFile } from "@/utils/index";


// 上传插件
import uploader from 'vue-simple-uploader'//上传组件
Vue.use(uploader)

Vue.use(dataV)

Vue.prototype.msgSuccess = function (msg, time = 500) {
  this.$message({ showClose: false, message: msg, type: "success", duration: time });
}
// console.log(2)
Vue.prototype.msgError = function (msg, time = 500) {
  this.$message({ showClose: false, message: msg, type: "error", duration: time });
}
Vue.prototype.msgInfo = function (msg, time = 500) {
  this.$message({ showClose: false, message: msg, type: "info", duration: time });
}


Vue.prototype.$axios = axios;

Vue.prototype.getDicts = getDicts
Vue.prototype.selectDictLabel = selectDictLabel
Vue.prototype.getFileDownloadPath = getFileDownloadPath
Vue.prototype.download = download
Vue.prototype.downloadFile = downloadFile

Vue.config.productionTip = false
Vue.use(CoolLightBox)
Vue.use(VueScrollActive)
Vue.use(VueTypedJs)
Vue.component(Pagination.name, Pagination)
Vue.use(Element, {
  size: Cookies.get('size') || 'medium', // set element-ui default size,

})



new Vue({
  router,
  store,
  created() {
    AOS.init({
      duration: 800,
      once: true,
    })
  },
  render: h => h(App)
}).$mount('#app')
