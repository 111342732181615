import request from '@/utils/request'


// 单个图片上传
export function uploadImg(param) {
    return request({
        url: '/common/upload',
        method: 'post',
        data: param
    })
}

// 注册
export function register(param) {
    return request({
        url: '/open/show/company/register',
        method: 'post',
        data: param
    })
}


// 查询企业预设信息(通过企业统一信用代码查询)
export function uniformCreditCode(uniformCreditCode) {
    return request({
        url: `/open/show/companyInfo/${uniformCreditCode}`,
        method: 'get',
    })
}

// 发送验证码
export function sendSms(param) {
    return request({
        url: `/open/show/sendSms`,
        method: 'get',
        params: param
    })
}