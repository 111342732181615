import Vue from 'vue'
import VueRouter from 'vue-router'
import Demo from '../views/Demo'

import Login from '../views/login'
import Regist from '../views/regist'
import HomeCenter from '../views/homeCenter'
import ProjectApplication from '../views/projectApplication'
import { baseURL } from "@/utils/request";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Demo',
        component: Demo,
        meta: {
            title: '大面铺子产业联盟线上平台',
        },
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            title: '大面铺子产业联盟线上平台',
        },
    },
    {
        path: '/regist',
        name: 'Regist',
        component: Regist,
        meta: {
            title: '大面铺子产业联盟线上平台',
        },
    },
    {
        path: '/home',
        name: 'HomeCenter',
        component: HomeCenter,
        meta: {
            title: '大面铺子产业联盟线上平台',
        },
    },
    {
        path: '/projectApplication/:id',
        name: 'ProjectApplication',
        component: ProjectApplication,
        meta: {
            title: '大面铺子产业联盟线上平台',
        },
    },
]

const router = new VueRouter({
    mode: 'history',
    base: '/',
    routes,
    // 切换路由后滚动条置顶
    scrollBehavior() {
        return {
            x: 0,
            y: 0
        }
    }
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title;
    next();
    window.scrollTo(0, 0)
});

export default router
