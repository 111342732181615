<template>
  <EditDialog
    tableTitle="详情"
    :open="open"
    @open-dialog="openDialog"
    @close-dialog="closeDialog"
    type="detail"
    PopOneWidth="820px"
  >
    <template slot="dialog-content">
      <el-descriptions>
        <el-descriptions-item label="主办单位"
          >{{detail}}</el-descriptions-item
        >
        <el-descriptions-item label="活动主题"
          >18100000000</el-descriptions-item
        >
        <el-descriptions-item label="活动内容" :span="2"
          >苏州市</el-descriptions-item
        >
        <el-descriptions-item label="协办单位"
          >18100000000</el-descriptions-item
        >
        <el-descriptions-item label="联系人">
          <el-tag size="small">学校</el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="联系电话"
          >江苏省苏州市吴中区吴中大道 1188 号</el-descriptions-item
        >
      </el-descriptions>
    </template>
  </EditDialog>
</template>

<script>
import EditDialog from "@/components/EditDialog";
import { getActiveDetail } from "@/api/index";

export default {
  name: "active-detail-dialog",
  components: { EditDialog },
  data() {
    return {
      open: false,
      detail: {},
      BZOptions: [],
    };
  },
  methods: {
    reset() {
      this.detail = {};
    },

    //关闭弹窗
    closeDialog() {
      this.open = false;
      this.reset();
    },
    //打开弹窗
    openDialog({ type, id }) {
      this.open = true;
      //   this.getDicts("help_type").then((response) => {
      //     this.BZOptions = response.data;
      //   });
      this.getData(id);
    },
    getData(id) {
      getActiveDetail(id).then((res) => {
        console.log(res, "0000");
        if (res.code == 200) {
          this.detail = res.data;
        }
      });
    },
  },
};
</script>
