import request from '@/utils/request'


// 查询项目申报列表
export function getDeclareInfo() {
    return request({
        url: '/open/show/declare/list',
        method: 'get'
    })
}

// 查询企业招聘列表
export function getOffers() {
    return request({
        url: '/open/show/offers/list',
        method: 'get'
    })
}

// 查询企业需求场地列表
export function getPlace() {
    return request({
        url: '/open/show/place/list',
        method: 'get'
    })
}

// 查询企业补贴列表
export function getSubsidy() {
    return request({
        url: '/open/show/subsidy/list',
        method: 'get'
    })
}

// 查询投资发布列表
export function getInvest() {
    return request({
        url: '/open/show/invest/list',
        method: 'get'
    })
}

// 市场机会
export function getMarket() {
    return request({
        url: '/open/show/marketOpportunities/list',
        method: 'get'
    })
}

// 市场机会
export function getMarketDetail(id) {
    return request({
        url: '/open/show/marketOpportunities/' + id,
        method: 'get'
    })
}

// 市场机会
export function addMarket(param) {
    return request({
        url: '/open/show/marketOpportunities',
        method: 'post',
        data: param
    })
}

// 企业活动
export function getActive() {
    return request({
        url: '/open/show/companyActivity/list',
        method: 'get'
    })
}

// 企业活动详情
export function getActiveDetail(id) {
    return request({
        url: '/open/show/companyActivity/' + id,
        method: 'get'
    })
}

// 查询贷款产品列表
export function getLoanProduct() {
    return request({
        url: '/open/show/loanProduct/list',
        method: 'get'
    })
}

// 查询我要帮助列表
export function getHelp() {
    return request({
        url: '/open/show/help/list',
        method: 'get'
    })
}

// 查询意见反馈列表
export function getFeedback() {
    return request({
        url: '/open/show/feedback/list',
        method: 'get'
    })
}

// 查询我的企业活动列表
export function getCompanyActivity() {
    return request({
        url: '/open/show/companyActivity/list/self',
        method: 'get'
    })
}


// 新增意见反馈列表
export function addFeedback(param) {
    return request({
        url: '/open/show/feedback',
        method: 'post',
        data: param
    })
}

// 新增帮助
export function addHelp(param) {
    return request({
        url: '/open/show/help',
        method: 'post',
        data: param
    })
}

// 新增场地
export function addPlace(param) {
    return request({
        url: '/open/show/place',
        method: 'post',
        data: param
    })
}

// 新增招聘
export function addTalent(param) {
    return request({
        url: '/open/show/offers',
        method: 'post',
        data: param
    })
}

// 新增企业活动/open/show/companyActivity
export function addActive(param) {
    return request({
        url: '/open/show/companyActivity',
        method: 'post',
        data: param
    })
}
