import axios from 'axios'
import { Notification, MessageBox, Message } from 'element-ui'
import store from '@/store'
import router from '@/router'
import { getToken } from '@/utils/auth'
import errorCode from '@/utils/errorCode'

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
axios.defaults.headers['lang'] = 'zh_cn'
axios.defaults.headers['Accept-Language'] = 'zh_cn'
const CancelToken = axios.CancelToken
export const baseURL = 'https://dmpzcylm.cn/api'
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: baseURL,
  // 超时
  timeout: 30 * 6000
})
// request拦截器
service.interceptors.request.use(
  config => {
    // 是否需要设置 token
    const isToken = (config.headers || {}).isToken === false
    if (getToken() && !isToken) {
      config.headers['Authorization'] = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
      config.cancelToken = new CancelToken(function (c) {//用于终止请求
        window.cancelAjax = c
      })
    }
    return config
  },
  error => {
    console.log(error)
    Promise.reject(error)
  })

// 响应拦截器
service.interceptors.response.use(res => {
  // 未设置状态码则默认成功状态
  const code = res.data.code || 200;

  // 获取错误信息
  const message = errorCode[code] || res.data.msg || errorCode['default']
  if (code === 401) {
    MessageBox.confirm(
      '登录状态已过期，您可以继续留在该页面，或者重新登录',
      '系统提示',
      {
        confirmButtonText: '重新登录',
        cancelButtonText: '取消',
        type: 'warning'
      }
    ).then(() => {
      console.log(44444);
      store
        .dispatch("LogOut")
        .then(() => {
          router.push({ path: "/login" });
        })
      // this.$router.push({ path: "/login" });
      // store.dispatch('LogOut').then(() => {
      //   // location.reload() // 为了重新实例化vue-router对象 避免bug
      //   this.$router.push({ path: "/login" });
      // })
    })
  } else if (code === 500) {
    Message({
      message: message,
      type: 'error'
    })
    return Promise.reject(new Error(message))
  }
  else if (code >= 5001 && code <= 5099) {
    return res.data
  }
  else if (code === 444) {

    return Promise.reject("error")
  } else if (code != 200) {
    Notification.error({
      title: message
    })
    return Promise.reject('error')
  }
  else {
    return res.data
  }
},
  error => {
    console.log('err' + error)
    if (error.message === '终止请求') {
      delete window.cancelAjax
      return
    }
    Message({
      message: "系统服务连接失败",
      type: 'error',
      duration: 5 * 1000,
      offset: 60
    })
    return Promise.reject(error)
  }
)
export default service
