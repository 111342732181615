import Cookies from 'js-cookie'

const TokenKey = 'Admin-PC-Token'

const Name = 'Name'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function getName() {
  return Cookies.get(Name)
}

export function setToken(token) {
  console.log(token, 'token');
  return Cookies.set(TokenKey, token)
}

export function setName(name) {
  return Cookies.set(Name, name)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function removeName() {
  return Cookies.remove(Name)
}

export const IMG_URL = process.env.VUE_APP_BASE_API

