<template>
  <Layout></Layout>
</template>

<script>
import Layout from "../components/common/Layout";
export default {
  name: "HomeCenter",
  components: {
    Layout,
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
