import request from '@/utils/request'

// 获取通知公告详细信息
export function getNotice(id) {
    return request({
        url: `/open/notice/${id}`,
        method: 'get'
    })
}

// 查询通知公告列表
export function getNoticeList() {
    return request({
        url: `/open/notice/list`,
        method: 'get'
    })
}

// 查询新闻列表
export function getNewList() {
    return request({
        url: `/open/news/list`,
        method: 'get'
    })
}

// 获取新闻详细信息
export function getNew(id) {
    return request({
        url: `/open/news/${id}`,
        method: 'get'
    })
}


// 查询企业之窗企业
export function getCompanyList() {
    return request({
        url: `/open/company/list`,
        method: 'get'
    })
}

// 查询重点企业
export function getKeyCompanyList() {
    return request({
        url: `/open/company/keyCompany`,
        method: 'get'
    })
}

// 免登陆园区企业信息
export function getStatisticsList() {
    return request({
        url: `/open/statistics/list`,
        method: 'get'
    })
}

// 查询企业服务实时数据列表
export function getServeList() {
    return request({
        url: `/open/data/list`,
        method: 'get'
    })
}

// 查询产业风采
export function getIndustrialStyleList(param) {
    return request({
        url: `/open/show/industrialStyle`,
        method: 'get',
        params: param
    })
}

// 查询企业预设信息
export function getCompanyInfoList(param) {
    return request({
        url: `/open/show/companyInfo/list`,
        method: 'get',
        params: param
    })
}