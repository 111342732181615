<template>
  <EditDialog
    tableTitle="发布机会"
    :open="open"
    @open-dialog="openDialog"
    @close-dialog="closeDialog"
    @handle-save="save"
    PopOneWidth="820px"
  >
    <template slot="dialog-content">
      <el-form ref="form" label-width="100px" :model="form">
        <el-form-item label="市场类型" prop="markType" class="ptb--10">
          <el-select
            v-model="form.markType"
            placeholder="请选择"
            class="mlr--10"
            style="width: 100%"
          >
            <el-option
              v-for="item in SCOptions"
              :key="item.dictValue"
              :label="item.dictLabel"
              :value="item.dictValue"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="产品名称" prop="name" class="ptb--10">
          <el-input placeholder="请输入" v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="产品类型" prop="productType" class="ptb--10">
          <el-select
            v-model="form.productType"
            placeholder="请选择"
            class="mlr--10"
            style="width: 100%"
          >
            <el-option
              v-for="item in CPOptions"
              :key="item.dictValue"
              :label="item.dictLabel"
              :value="item.dictValue"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="有效期限" prop="endTime">
          <el-date-picker
            v-model="form.endTime"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="请选择反馈日期"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item label="联系人" prop="linkName" class="ptb--10">
          <el-input placeholder="请输入" v-model="form.linkName"></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="linkPhone" class="ptb--10">
          <el-input placeholder="请输入" v-model="form.linkPhone"></el-input>
        </el-form-item>
        <el-form-item label="产品介绍" prop="content" class="ptb--10">
          <el-input
            type="textarea"
            :rows="4"
            placeholder="请输入"
            v-model="form.content"
          ></el-input>
        </el-form-item>
        <el-form-item label="产品图片" prop="pic" class="ptb--10">
          <el-upload
            class="avatar-uploader"
            :action="pre + '/common/upload'"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <img v-if="form.pic" :src="form.pic" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="是否使用外链" prop="outUrlFlag" class="ptb--10">
          <el-radio v-model="form.outUrlFlag" :label="true">是</el-radio>
          <el-radio v-model="form.outUrlFlag" :label="false">否</el-radio>
        </el-form-item>
        <el-form-item
          label="外链地址"
          prop="outUrl"
          class="ptb--10"
          v-show="form.outUrlFlag"
        >
          <el-input placeholder="请输入" v-model="form.outUrl"></el-input>
        </el-form-item>
      </el-form>
    </template>
  </EditDialog>
</template>

<script>
import EditDialog from "@/components/EditDialog";
import { addMarket } from "@/api/index";
import { addFile } from "@/api/dict";
import { baseURL } from "@/utils/request";

export default {
  name: "market-dialog",
  components: { EditDialog },
  data() {
    return {
      open: false,
      pre: baseURL,
      form: {
        productType: "",
        markType: "",
        endTime: "",
        name: "",
        content: "",
        linkName: "",
        linkPhone: "",
        pic: "",
        fileIds: "",
        outUrlFlag: false,
        outUrl: "",
      },
      rules: {},
      CPOptions: [],
      SCOptions: [
        { dictLabel: "需求", dictValue: "1" },
        { dictLabel: "供给", dictValue: "2" },
      ],
    };
  },
  methods: {
    reset() {
      this.form = {
        productType: "",
        markType: "",
        endTime: "",
        name: "",
        content: "",
        linkName: "",
        linkPhone: "",
        pic: "",
        fileIds: "",
        outUrlFlag: false,
        outUrl: "",
      };
    },
    handleAvatarSuccess(res, file) {
      console.log(res, file);
      if (res.code == 200) {
        this.form.pic = res.url;
        let init = {
          fileName: res.fileName,
          fileShowName: res.fileName.split(".")[0],
          fileUrl: res.url,
        };
        addFile(init).then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.form.fileIds = res.data.id;
          }
        });
      }
    },
    beforeAvatarUpload(file) {
      console.log(file, "file");
      // return;
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 20;

      if (!isJPG) {
        this.$message.error("上传图片只能是 JPG |PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },

    // 图片上传成功的回调
    handleUpload(res) {
      // this.$set(this.form, "businessLicenseImg", res.url);
      console.log(res);
    },
    //提交
    save() {
      const submitServer = addMarket;
      if (!this.form.outUrlFlag) {
        this.form.outUrl = "";
      }
      const credentialForm = this.$refs["form"].validate();
      Promise.all([credentialForm]).then(([a, b]) => {
        delete this.form.pic;
        submitServer(this.form).then((res) => {
          this.open = false;
          this.reset();
          this.msgSuccess(res.msg);
          this.$emit("getTableSource");
        });
      });
    },
    //关闭弹窗
    closeDialog() {
      this.open = false;
      this.reset();
    },
    //打开弹窗
    openDialog() {
      this.open = true;
      this.getDicts("product_type").then((response) => {
        this.CPOptions = response.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
</style>
