<template>
  <div class="upload-more-image">
    <template>
      <el-upload
        class="singe-upload-box"
        :style="{
          height: height + 'px',
          width: width + 'px',
          'line-height': width + 'px !important',
        }"
        :action="pre + action"
        :headers="header"
        list-type="picture-card"
        :on-success="handleSuccess"
        :on-progress="handleProgress"
        :on-remove="handleRemove"
        :on-error="handleError"
        :on-exceed="handleExceed"
        :file-list="images"
        :limit="limit"
        :before-upload="beforeUpload"
      > 
        <img v-if="imageUrl" :src="imageUrl" class="avatar" />
        <i class="el-icon-plus"></i>
      </el-upload>
    </template>
  </div>
</template>

<script>
import { getToken } from "@/utils/auth";
import { baseURL } from "@/utils/request";
export default {
  name: "UploadMoreImage",
  props: {
    value: {
      type: Array,
      default: [],
    },
    height: {
      type: Number,
      default: 100,
    },
    width: {
      type: Number,
      default: 100,
    },
    fileEx: {
      type: String,
      default: "jpg | jpeg | gif | png",
    },
    exSize: {
      type: Number,
      default: 2,
    },
    limit: {
      type: Number,
      default: 1,
    },
    action: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      header: { Authorization: "Bearer " + getToken() }, // 设置上传头部
      exStr: "",
      images: [],
      imageFiles: [],
      pre: baseURL,
    };
  },
  watch: {
    value: {
      handler(newValue) {
        if (newValue) {
          this.$nextTick(() => {
            this.setValue(newValue);
          });
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    /** 图片上传前的操作 */
    beforeUpload(file) {
      //验证图片格式
      const fileExarr = file.name.split(".");
      const fileExStr = fileExarr[fileExarr.length - 1].toLowerCase();
      const isFileSize = file.size / 1024 / 1024 < this.exSize;
      const fileExs = this.fileEx
        .split("|")
        .map((v) => v.replace(/^\s*|\s*$/g, ""));
      if (fileExs.indexOf(fileExStr) === -1) {
        this.msgInfo(`文件只能是 ${this.exStr} 格式!`);
        return false;
      }
      if (!isFileSize) {
        this.msgInfo(`文件大小不能超过 ${this.exSize}MB!`);
        return false;
      }
      // if ((file.size / 1024) > 1024) { // 大于1mb的图片全部0.5压缩
      //     let _this = this;
      //     return new Promise((resolve, reject) => {
      //         let image = new Image(),
      //             resultBlob = '';
      //         image.src = URL.createObjectURL(file);
      //         image.onload = () => {
      //             // 调用方法获取blob格式，方法写在下边
      //             resultBlob = _this.$http.compressUpload(image,0.5);
      //             resolve(resultBlob);
      //         };
      //         image.onerror = () => {
      //             reject();
      //         };
      //     });
      // }
    },
    /** 删除文件 */
    handleRemove(file) {
      const fileUrl = !file.hasOwnProperty("response")
        ? file.url
        : file.response.data; // 找出下标
      const fileIndex = this.imageFiles.indexOf(fileUrl);
      this.imageFiles.splice(fileIndex, 1);
      this.$emit("change", this.imageFiles);
    },
    /** 限制选择文件 */
    handleExceed(files, fileList) {
      this.msgInfo(
        `当前限制选择 ${this.limit} 个文件，本次选择了 ${
          files.length
        } 个文件，共选择了 ${files.length + fileList.length} 个文件`
      );
    },
    /** 上传进度 */
    handleProgress(event) {
      // this.progress = Math.floor(event.percent);
    },
    /** 图片上传成功的回调 */
    handleSuccess(res) {
      if (res.code == 200) {
        this.imageFiles.push(res.url);
        this.$emit("change", res.url);
      } else {
        this.msgError(res.message);
      }
    },
    handleError: function (res) {
      this.progress = 0;
      this.msgError(res.msg || "文件上传失败，请重新上传！");
    },
    setValue(newValue) {
      this.images = [];
      this.imageFiles = [];
      newValue.forEach((val, index) => {
        this.images.push({ name: index, url: "/" + val });
        this.imageFiles.push(val);
      });
    },
  },
  created() {
    this.value.forEach((val, index) => {
      this.images.push({ name: index, url: "/" + val });
      this.imageFiles.push(val);
    });
    this.exStr = this.fileEx.toUpperCase();
  },
};
</script>

<style lang="scss">
.upload-image {
  position: relative;
  width: 100%;
}
.singe-upload-box {
  width: 100%;
  //上传成功后的图片
  .upload-image-box {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  .el-upload--picture-card {
    width: 100%;
    height: 100%;
  }
  .el-upload-list__item {
    width: 100px;
    height: 100px;
    line-height: 100px;
    .el-upload-list__item-status-label {
      display: none;
    }
  }
}
</style>
