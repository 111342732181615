<template>
  <!-- 新增和修改类弹框 -->
  <el-dialog
    :title="tableTitle"
    append-to-body
    :close-on-click-modal="false"
    :visible.sync="openlog"
    @close="closeDialog"
    :width="PopOneWidth"
    destroy-on-close
  >
    <div style="padding: 10px 20px">
      <slot name="dialog-content"></slot>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button v-if="type !== 'detail'" type="primary" @click="save">{{
        savebtn
      }}</el-button>
      <el-button @click="closeDialog">{{
        type == "detail" ? "关闭" : cancelbtn
      }}</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  name: "Editdialog",
  props: {
    savebtn: {
      type: String,
      default: "确定",
    }, // 确定按钮默认文案
    cancelbtn: {
      type: String,
      default: "取消",
    }, // 取消按钮默认文案
    tableTitle: {
      type: String,
      default: "",
    }, // 默认标题
    PopOneWidth: {
      type: String || Number,
      default: "310px",
    }, // 默认弹框宽度
    open: {
      type: Boolean,
      default: false,
    }, // 打开或关闭弹框
    type: {
      type: String,
      default: "",
    }, // 当type为detail时，弹框为详情
  },
  watch: {
    open: {
      handler(newValue) {
        this.openlog = newValue;
      },
      immediate: true,
    },
  },
  data() {
    return {
      openlog: false,
    };
  },
  methods: {
    //保存按钮
    save() {
      this.$emit("handle-save");
    },
    //关闭弹窗
    closeDialog() {
      this.$emit("close-dialog");
      this.openlog = false;
    },
    //修改弹窗
    editDialog() {
      this.$emit("edit-dialog");
      this.openlog = true;
    },
    //打开弹窗
    openDialog() {
      this.$emit("open-dialog");
      this.openlog = true;
    },
  },
};
</script>
