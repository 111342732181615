<template>
  <!-- Start Error-Area Area  -->
  <div class="relative">
    <div class="login-bg">
      <img src="../assets/img/login.png" alt="登录" />
      <div class="bg-title">大面铺子产业联盟线上平台</div>
    </div>
    <div class="login-content plr--40">
      <div class="container plr-60">
        <div class="row align-item-center mlr-60">
          <div class="col-lg-12">
            <div class="plr-60">
              <div class="title text-center pb--40">用户登录</div>

              <el-form ref="form" label-width="60px" :model="form">
                <el-form-item label="账号" prop="account" class="ptb--10">
                  <el-input
                    placeholder="请输入账号"
                    v-model="form.account"
                  ></el-input>
                </el-form-item>
                <el-form-item label="密码" prop="accountPwd" class="ptb--10">
                  <el-input
                    show-password
                    placeholder="请输入密码"
                    v-model="form.accountPwd"
                  ></el-input>
                </el-form-item>
                <el-form-item label-width="120px">
                  <el-button type="primary" @click="onSubmit" class="submit">
                    登录
                  </el-button>
                  <div>
                    还未创建账号？
                    <router-link to="/regist"> 立即注册 </router-link>
                  </div>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Layout from "../components/common/Layout";
import Icon from "../components/icon/Icon";
import { encrypt, decrypt } from "@/utils/jsencrypt";
import Upload from "@/components/upload/index";
import { login } from "@/api/login";

export default {
  name: "Login",
  components: { Icon, Layout, Upload },
  data() {
    return {
      activeIndex: 2,
      codeUrl: "",
      form: {
        account: "",
        accountPwd: "",
      },
    };
  },
  created() {},
  methods: {
    onSubmit() {
      const { accountPwd, account } = this.form;
      if (!accountPwd) {
        this.$message.error("请输入密码");
      }
      if (!account) {
        this.$message.error("请输入账号");
      }
      const init = {
        account,
        accountPwd: encrypt(accountPwd),
      };
      this.$store
        .dispatch("Login", init)
        .then(() => {
          this.$router.push({ path: "/" });
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.login-bg {
  width: 100%;
  height: 494px;

  img {
    width: 100%;
    height: 100%;
  }
}

.login-content {
  width: 600px;
  height: 479px;
  background: #ffffff;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 205px;
  left: 50%;
  transform: translateX(-50%);
}

.title {
  font-size: 22px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #13a3eb;
  letter-spacing: 1px;
  padding-top: 30px;
}

.upload {
  display: flex;
  justify-content: center;
}

.submit {
  width: 330px;
  height: 44px;
  background: #12a1ec;
  border-radius: 8px;
}

.bg-title {
  position: absolute;
  top: 90px;
  left: 193px;
  color: #ffffff;
  font-size: 26px;
}
</style>
