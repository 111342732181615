<template>
  <EditDialog
    tableTitle="发布活动"
    :open="open"
    @open-dialog="openDialog"
    @close-dialog="closeDialog"
    @handle-save="save"
    PopOneWidth="820px"
  >
    <template slot="dialog-content">
      <el-form ref="form" label-width="100px" :model="form">
        <el-form-item label="活动主题" prop="activityTitle" class="ptb--10">
          <el-input
            placeholder="请输入"
            v-model="form.activityTitle"
          ></el-input>
        </el-form-item>
        <el-form-item label="活动内容" prop="activityDetail" class="ptb--10">
          <el-input
            type="textarea"
            :rows="4"
            placeholder="请输入"
            v-model="form.activityDetail"
          ></el-input>
        </el-form-item>
        <el-form-item label="活动地址" prop="address" class="ptb--10">
          <el-input placeholder="请输入" v-model="form.address"></el-input>
        </el-form-item>
        <el-form-item label="主办单位" prop="organizer" class="ptb--10">
          <el-input placeholder="请输入" v-model="form.organizer"></el-input>
        </el-form-item>
        <el-form-item label="协办单位" prop="coOrganiser" class="ptb--10">
          <el-input placeholder="请输入" v-model="form.coOrganiser"></el-input>
        </el-form-item>
        <el-form-item label="联系人" prop="activityContact" class="ptb--10">
          <el-input
            placeholder="请输入"
            v-model="form.activityContact"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="activityTel" class="ptb--10">
          <el-input placeholder="请输入" v-model="form.activityTel"></el-input>
        </el-form-item>
        <el-form-item label="活动时间" prop="activityDate">
          <el-date-picker
            v-model="form.activityDate"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="图片" prop="pic" class="ptb--10">
          <el-upload
            class="avatar-uploader"
            :action="pre + '/common/upload'"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <img v-if="form.pic" :src="form.pic" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="是否使用外链" prop="outUrlFlag" class="ptb--10">
          <el-radio v-model="form.outUrlFlag" :label="true">是</el-radio>
          <el-radio v-model="form.outUrlFlag" :label="false">否</el-radio>
        </el-form-item>
        <el-form-item
          label="外链地址"
          prop="outUrl"
          class="ptb--10"
          v-show="form.outUrlFlag"
        >
          <el-input placeholder="请输入" v-model="form.outUrl"></el-input>
        </el-form-item>
      </el-form>
    </template>
  </EditDialog>
</template>

<script>
import EditDialog from "@/components/EditDialog";
import { addActive } from "@/api/index";
import { baseURL } from "@/utils/request";
import { addFile } from "@/api/dict";

export default {
  name: "active-dialog",
  components: { EditDialog },
  data() {
    return {
      tableTitle: "发布活动",
      open: false,
      pre: baseURL,
      form: {
        activityDate: "",
        activityTitle: "",
        activityDetail: "",
        address: "",
        organizer: "",
        coOrganiser: "",
        activityContact: "",
        activityTel: "",
        pic: "",
        fileIds: "",
        outUrlFlag: false,
        outUrl: "",
      },
      rules: {},
    };
  },
  methods: {
    reset() {
      this.form = {
        activityDate: "",
        activityTitle: "",
        activityDetail: "",
        address: "",
        organizer: "",
        coOrganiser: "",
        activityContact: "",
        activityTel: "",
        pic: "",
        fileIds: "",
        outUrlFlag: false,
        outUrl: "",
      };
    },
    handleAvatarSuccess(res, file) {
      console.log(res, file);
      if (res.code == 200) {
        this.form.pic = res.url;
        let init = {
          fileName: res.fileName,
          fileShowName: res.fileName.split(".")[0],
          fileUrl: res.url,
        };
        addFile(init).then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.form.fileIds = res.data.id;
          }
        });
      }
    },
    beforeAvatarUpload(file) {
      console.log(file, "file");
      // return;
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 20;

      if (!isJPG) {
        this.$message.error("上传图片只能是 JPG |PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    //提交
    save() {
      const submitServer = addActive;
      const credentialForm = this.$refs["form"].validate();
      if (!this.form.outUrlFlag) {
        this.form.outUrl = "";
      }
      Promise.all([credentialForm]).then(([a, b]) => {
        delete this.form.pic;
        submitServer(this.form).then((res) => {
          this.open = false;
          this.reset();
          this.msgSuccess(res.msg);
          this.$emit("getTableSource");
        });
      });
    },
    //关闭弹窗
    closeDialog() {
      this.open = false;
      this.reset();
    },
    //打开弹窗
    openDialog() {
      this.open = true;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
</style>
