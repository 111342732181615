import JSEncrypt from 'jsencrypt/bin/jsencrypt'

const publicKey = 'MFwwDQYJKoZIhvcNAQEBBQADSwAwSAJBANL378k3RiZHWx5AfJqdH9xRNBmD9wGD\n' +
  '2iRe41HdTNF8RUhNnHit5NpMNtGL0NPTSSpPjjI1kJfVorRvaQerUgkCAwEAAQ=='

const privateKey = 'MIIBUwIBADANBgkqhkiG9w0BAQEFAASCAT0wggE5AgEAAkEA0vfvyTdGJkdbHkB8\n' +
  'mp0f3FE0GYP3AYPaJF7jUd1M0XxFSE2ceK3k2kw20YvQ09NJKk+OMjWQl9WitG9p\n' +
  'B6tSCQIDAQABAkA2SimBrWC2/wvauBuYqjCFwLvYiRYqZKThUS3MZlebXJiLB+Ue\n' +
  '/gUifAAKIg1avttUZsHBHrop4qfJCwAI0+YRAiEA+W3NK/RaXtnRqmoUUkb59zsZ\n' +
  'UBLpvZgQPfj1MhyHDz0CIQDYhsAhPJ3mgS64NbUZmGWuuNKp5coY2GIj/zYDMJp6\n' +
  'vQIgUueLFXv/eZ1ekgz2Oi67MNCk5jeTF2BurZqNLR3MSmUCIFT3Q6uHMtsB9Eha\n' +
  '4u7hS31tj1UWE+D+ADzp59MGnoftAiBeHT7gDMuqeJHPL4b+kC+gzV4FGTfhR9q3\n' +
  'tTbklZkD2A=='

const serverPublicKey = 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAyZstT+AC5/Iq9R03N37i\n' +
  'ZNuB1t7O23wNPZQU1cVcQELLQQMl6nFzA6Odpt1es5VQ1Ccw7b6dBF6UlmzAF2K42bwsj/JO10fJbsNAVavkasz\n' +
  'TGLjR4NONutg7u4VxbLEdYrYL2VuKh0K2h8fXoe0XSsUI3VwXg/RmUp3RhE3NJswhBw9XTyTjxCZNLPrgBlrFjyE\n' +
  'S0b2qR+q+7SyqvF2AkaULPBofYaWS8zNrMe9KkoV70DascFePYaKl2NboH3iDY/q9nbUqEhPwa69CAa7TvAV2R82n\n' +
  '4NLtNDa2RUdFO+qk1zttXyUbkLX39dj9+/ncC6I8QRvMFOqMRpzv8uVpgwIDAQAB'

const externalPrivateKey = 'MIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQCMpxtBASt7i5oa5oh8eck3\n' +
  'E+kW9nBP/VMoPAvSkhS9s861ZkqgvxAp/WwcsuVUhJpBlF40LPtxpGcZBxq2oH0Rg30uOz4gq4R1IxhnEACWb6C6ziRMksdqrorX5\n' +
  'hjSrHlsHybRrqwT3yc1OtvKA3UMvz8Rvj4CeVUuRYEaed9a0enGhWf2GCVgF+FyYmwBWWqYWEj/8+RffIxbo1UKLk5Gu1d0wBUS5GP9\n' +
  'XzlRleRMCgrJlTUbK9LOxMIP6dyk2hYj8jrzQ9InmrTRvsjkCNsqriQ15QimmNGKdA3WOLqQ5uMnsN7CTaZAgfvyBnOXfyWyov0Ua6xoj\n' +
  '6TyRkoqJupVAgMBAAECggEANI/CLJGuWTX7OfBKsxGj8zQ6l/0yTYIikFeYJ3G11IqcRcfbPaUI+lgdDcCA2QvQxY8XgTIKKRbB/GVeQz9\n' +
  'IXcIghqVW+0h9cHTUCre9MHq4uvaMNB1p7mEZu8LeJeSUX6ZokFVRMv/inA7J0f1mwYO6ovvQGoaTIX6R134RzNM1OqnaFO+r/GytNFVUnnMd\n' +
  '2Z9oVtCwoJ6VzCYYXZw3JA+7PY0XURglCpDgHCoaTnl/dXpaCzzoPIwqyBeKJo+ne7O46zpGOAtqCuJho17ZpPCE58y3gCY/ffSq7Jv8D+kn9\n' +
  'K6JvT8v7pSyHPJQ+ua1luL83nnNpld74S/1e9pPoQKBgQDEnruu522US1mpIdYqwpmjhVS23Gxvp0MkaMjJt17MihY1YOBP8fc/pxXgfOp5AXccs\n' +
  'roGC/QIxJA46eoVXGqIwClg2JqsmdNp16zKI5XAYGAv3zVw44CXyY7rGRBhm9V8hyTzw0h3+gOxn9hBIYogEUjVE4QCNmU5dtQwfLpqjQKBgQC3I\n' +
  'WEqflYZKk+MpduyYNaqinH2ppjC3JZeey40BkzhaJ29ZKO6/Mk+29/ZnphOcikrSnFdTpgIqG+PMqoizol5jpuNrHRi+1FCPDNq85RoPMWJTGTSFxi\n' +
  'bC9RkO74BAiRlIlLXVi/jnQE3D2nvjMy9/apaVDpQX5TxNWSBLuew6QKBgQCit+8BP7vbF0TbiQoyFxyJTIWgU+lVueoCDsnkzmGhCsJ4aHUbFFeXkRK\n' +
  '01wxBGmpWEL+M04WhX/Mvdr5n8TSlecqEwJ25LBDfrkLsguii7d+MnsAebdj1RTSG8hYxgYL7bOiy57FowTsfW/bfZ/9krIlG9cCxCA1bsjDG8+Je3QKBgG\n' +
  'vkE43ofqs23UUc1lHCcdZp2HibcDalpUWmxvLJn3PRodVaPIhGs/3/pmNc55iX859YrW9uhW3cZlV0YP4+tonSNd0C9FL4g8hFFXn2ZcH/w9V/F4sbJlj6Y\n' +
  'DhpZijL7UtULIBHe9Fax8N7pksGpf1tPHcZVn3RwjtV3I+rZb85AoGBAJKkmIJFTzQSrbePBlTeMDAeL6moOBg9g2X/9x/PRZcr7rjTNmRZ7LSBEx9Te5U7x9J\n' +
  '8uTZgI6Zdr0vBKYD/dO5sPMkDoDHjM2GvKbF4uRyatuIfmPtti7z8YyomZvWspe5AuQXR8RcmkkkAicmI7R3w6Rntke3z3ZfMZuwKi5aA'

// 加密
export function encrypt(txt) {
  const encryptor = new JSEncrypt()
  encryptor.setPublicKey(publicKey) // 设置公钥
  return encryptor.encrypt(txt) // 对需要加密的数据进行加密
}

// 解密
export function decrypt(txt) {
  const encryptor = new JSEncrypt()
  encryptor.setPrivateKey(privateKey)
  return encryptor.decrypt(txt)
}

// 加密数据传输服务端
export function encryptServerData(obj){
  const encryptor = new JSEncrypt()
  encryptor.setPublicKey(serverPublicKey)
  return encryptor.encrypt(obj)
}

// 解密外部跳转数据
export function decryptExternal(txt) {
  const encryptor = new JSEncrypt()
  encryptor.setPrivateKey(externalPrivateKey)
  return encryptor.decrypt(txt)

}

