import { baseURL } from './request'

// 回显数据字典
export function selectDictLabel(datas, value) {
    if (datas == null || datas.length == 0) return value
    var actions = []
    Object.keys(datas).map((key) => {
        if (datas[key].dictValue == ('' + value)) {
            actions.push(datas[key].dictLabel)
            return false
        }
    })
    return actions.join('')
}

/**
 * 通用获取file 下载路径
 * @param {*} fileName 文件名
 */

export function getFileDownloadPath(fileName) {
    console.log(fileName, '000');
    if (fileName == undefined || fileName == null || fileName == '') return null

    return baseURL + '/open/common/download?fileName=' + fileName

}

// 通用下载方法
export function download(fileName) {
    window.location.href = baseURL + '/open/common/download?fileName=' + fileName
}




export function downloadFile(fileName) {
    var request = new XMLHttpRequest();
    var url =
        baseURL + '/open/common/download';
    url += "?";
    url += "fileName=";
    url += fileName;
    request.open("GET", url);
    request.setRequestHeader("Content-Disposition", "attachment");
    request.responseType = "blob"; //定义响应类型
    request.onload = function () {
        var url = window.URL.createObjectURL(this.response);
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.href = url;
        a.download = fileName;
        a.click();
    };
    request.send();

}

export function Exstr(str) {
    return str && str.replace(/\s*/g, "")
}

