<template>
  <div>
    <!-- Start Header Area -->
    <header :class="[`header-default`]">
      <div class="position-relative custom-header">
        <div class="pt--30">
          <!-- Start Header Btn -->
          <div class="flex" v-show="name == ''">
            <div class="btn-left text-center">
              <router-link to="/login"> 登录 </router-link>
            </div>
            <div class="btn-right text-center">
              <router-link to="/regist"> 注册 </router-link>
            </div>
          </div>
          <div class="flex pr--40" v-show="name != ''">
            欢迎{{ name }}！ &nbsp;
            <!-- <router-link to="/home" class="classCenter">个人中心</router-link> -->
            <a class="solid" @click="checkout">退出</a>
            &nbsp;
          </div>

          <!-- End Header Btn  -->
        </div>
      </div>
    </header>
    <!-- End Header Area -->
  </div>
</template>

<script>
import Icon from "../../icon/Icon";
import AppFunctions from "../../../helpers/AppFunctions";
import Nav from "./Nav";
import { getToken } from "@/utils/auth";

export default {
  name: "Header",
  props: {
    data: {
      default: null,
    },
  },
  components: { Nav, Icon },
  data() {
    return {
      AppFunctions,
      name: "",
    };
  },
  methods: {
    toggleStickyHeader() {
      const scrolled = document.documentElement.scrollTop;
      if (scrolled > 100) {
        AppFunctions.addClass(".header-default", "sticky");
      } else if (scrolled <= 100) {
        AppFunctions.removeClass(".header-default", "sticky");
      }
    },
    checkout() {
      this.$confirm("确定退出系统吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$store
            .dispatch("LogOut")
            .then(() => {
              location.reload();
            })
            .catch(() => {});
        })
        .catch(() => {
          
        });
    },
  },
  created() {
    window.addEventListener("scroll", this.toggleStickyHeader);
  },
  mounted() {
    this.toggleStickyHeader();
    if (getToken()) {
      console.log(this.$store, "-----store");
      this.name = this.$store.getters.account;
    }
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.toggleStickyHeader);
  },
};
</script>
<style lang="scss" scoped>
.custom-header {
  width: 100%;
  height: 92px;
  background-image: url("../../../assets/img/header.png");
  background-size: 100% 100%;
}

.btn-left {
  background-color: #e7af1c;
  width: 78px;
  height: 30px;
  color: #fff;
  line-height: 30px;
  font-size: 18px;
  margin-right: 15px;
  cursor: pointer;

  a {
    color: #fff;
  }

  a:hover {
    color: #fff;
  }

  &:hover {
    color: #fff;
  }
}
.btn-right {
  background-color: #fff;
  width: 78px !important;
  height: 30px;
  color: #e7af1c;
  line-height: 30px;
  font-size: 18px;
  cursor: pointer;
  margin-right: calc((100% - 1085px) / 2);
}
.flex {
  display: flex;
  justify-content: flex-end;
}

.classCenter {
  width: 100px;
  padding-left: 30px;
  text-decoration: underline;
}

.solid {
  text-decoration: underline;
}
</style>
