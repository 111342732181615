<template>
  <EditDialog
    tableTitle="发布招聘"
    :open="open"
    @open-dialog="openDialog"
    @close-dialog="closeDialog"
    @handle-save="save"
    PopOneWidth="820px"
  >
    <template slot="dialog-content">
      <el-form ref="form" label-width="100px" :model="form">
        <el-form-item label="招聘岗位" prop="job" class="ptb--10">
          <el-input placeholder="请输入" v-model="form.job"></el-input>
        </el-form-item>
        <el-form-item label="薪资范围" prop="salary" class="ptb--10">
          <el-input placeholder="请输入" v-model="form.salary"></el-input>
        </el-form-item>
        <el-form-item label="招聘人数" prop="personNum" class="ptb--10">
          <el-input placeholder="请输入" v-model="form.personNum"></el-input>
        </el-form-item>
        <el-form-item label="职位标签" prop="stationLabel" class="ptb--10">
          <el-select
            v-model="form.stationLabel"
            multiple
            filterable
            allow-create
            default-first-option
            placeholder="请输入职位标签"
            style="width: 100%"
            class="custom"
          >
            <el-option
              v-for="item in ZWOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="经验要求" prop="jobRequirements" class="ptb--10">
          <el-input
            placeholder="请输入"
            v-model="form.jobRequirements"
          ></el-input>
        </el-form-item>
        <el-form-item label="职位描述" prop="introduceContent" class="ptb--10">
          <el-input
            type="textarea"
            :rows="4"
            placeholder="请输入"
            v-model="form.introduceContent"
          ></el-input>
        </el-form-item>
        <el-form-item label="所在区域" prop="area" class="ptb--10">
          <el-input placeholder="请输入" v-model="form.area"></el-input>
        </el-form-item>
        <el-form-item label="联系人" prop="linkName" class="ptb--10">
          <el-input placeholder="请输入" v-model="form.linkName"></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="linkPhone" class="ptb--10">
          <el-input placeholder="请输入" v-model="form.linkPhone"></el-input>
        </el-form-item>
        <el-form-item label="详细地址" prop="address" class="ptb--10">
          <el-input placeholder="请输入" v-model="form.address"></el-input>
        </el-form-item>
        <el-form-item label="截止日期" prop="endTime">
          <el-date-picker
            v-model="form.endTime"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="是否使用外链" prop="outUrlFlag" class="ptb--10">
          <el-radio v-model="form.outUrlFlag" :label="true">是</el-radio>
          <el-radio v-model="form.outUrlFlag" :label="false">否</el-radio>
        </el-form-item>
        <el-form-item
          label="外链地址"
          prop="outUrl"
          class="ptb--10"
          v-show="form.outUrlFlag"
        >
          <el-input placeholder="请输入" v-model="form.outUrl"></el-input>
        </el-form-item>
      </el-form>
    </template>
  </EditDialog>
</template>

<script>
import EditDialog from "@/components/EditDialog";
import { addTalent } from "@/api/index";

export default {
  name: "talent-dialog",
  components: { EditDialog },
  data() {
    return {
      open: false,

      form: {
        endTime: "",
        companyName: "",
        salary: "",
        introduceContent: "",
        linkName: "",
        linkPhone: "",
        area: "成都-龙泉驿区",
        address: "",
        stationLabel: [],
        personNum: "",
        outUrlFlag: false,
        outUrl: "",
      },
      rules: {},
      ZWOptions: [],
    };
  },
  methods: {
    reset() {
      this.form = {
        endTime: "",
        companyName: "",
        salary: "",
        introduceContent: "",
        linkName: "",
        linkPhone: "",
        area: "成都-龙泉驿区",
        address: "",
        stationLabel: [],
        personNum: "",
        outUrlFlag: false,
        outUrl: "",
      };
    },
    //提交
    save() {
      const submitServer = addTalent;
      const credentialForm = this.$refs["form"].validate();
      console.log(this.form, "3333");
      let init =
        (this.form.stationLabel.length && this.form.stationLabel.toString()) ||
        "";
      if (!this.form.outUrlFlag) {
        this.form.outUrl = "";
      }
      Promise.all([credentialForm]).then(([a, b]) => {
        submitServer({ ...this.form, stationLabel: init }).then((res) => {
          this.open = false;
          this.reset();
          this.msgSuccess(res.msg);
          this.$emit("getTableSource");
        });
      });
    },
    //关闭弹窗
    closeDialog() {
      this.open = false;
      this.reset();
    },
    //打开弹窗
    openDialog() {
      this.open = true;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-select__input {
  border: none !important;
}
</style>
