<template>
  <!-- Start Error-Area Area  -->
  <div class="relative">
    <div class="login-bg">
      <img src="../assets/img/bj.png" alt="注册" />
    </div>
    <div class="login-content">
      <div class="container">
        <div class="row align-item-center">
          <div class="col-lg-12">
            <div>
              <div class="title text-center">用户注册</div>
              <!-- <div class="pt--30">
                <el-steps :active="activeIndex" simple>
                  <el-step title="上传营业执照" icon="el-icon-upload"></el-step>
                  <el-step title="上传商户图标" icon="el-icon-upload"></el-step>
                  <el-step title="基本信息" icon="el-icon-edit"></el-step>
                </el-steps> -->
              </div>
              <!-- <div v-if="activeIndex == 1">
                <div class="upload pt--60">
                  <el-upload
                    class="avatar-uploader"
                    :action="pre + '/common/upload'"
                    :show-file-list="false"
                    :on-success="handleAvatarSuccess"
                    :before-upload="beforeAvatarUpload"
                  >
                    <img
                      v-if="form.businessLicenseImg"
                      :src="form.businessLicenseImg"
                      class="avatar"
                    />
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                </div>
                <div class="upload">请上传您的营业执照</div>
              </div>
              <div v-if="activeIndex == 2">
                <div class="upload pt--60">
                  <el-upload
                    class="avatar-uploader"
                    :action="pre + '/common/upload'"
                    :show-file-list="false"
                    :on-success="handleAvatarSuccess1"
                    :before-upload="beforeAvatarUpload"
                  >
                    <img v-if="form.icon" :src="form.icon" class="avatar" />
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                </div>
                <div class="upload">请上传您的商户图标</div>
              </div> -->
              <div class="pt--20">
                <el-form
                  ref="form"
                  label-width="110px"
                  :model="form"
                  :rules="rules"
                >
                  <el-form-item v-show="false" label="登录账号" prop="account">
                    <el-input
                      placeholder="请输入手机号"
                      v-model="form.account"
                      type="tel"
                      maxlength="11"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="统一信用代码" prop="uniformCreditCode">
                    <el-input
                      placeholder="请输入"
                      v-model="form.uniformCreditCode"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="企业名称" prop="name">
                    <el-input
                      placeholder="请输入"
                      v-model="form.name"
                    ></el-input>
                  </el-form-item>

                  <el-form-item label="成立时间" prop="foundingTime">
                    <el-date-picker
                      v-model="form.foundingTime"
                      type="date"
                      value-format="yyyy-MM-DD"
                      placeholder="选择日期"
                      style="width: 100%"
                    >
                    </el-date-picker>
                  </el-form-item>
                  <el-form-item label="所属社区" prop="community">
                    <el-select
                      v-model="form.community"
                      placeholder="请选择"
                      style="width: 100%"
                    >
                      <el-option
                        v-for="item in SQOptions"
                        :key="item.dictValue"
                        :label="item.dictLabel"
                        :value="item.dictValue"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="所属区域" prop="area">
                    <el-input
                      placeholder="请输入"
                      v-model="form.area"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="企业属性" prop="propertyType">
                    <el-select
                      v-model="form.propertyType"
                      placeholder="请选择"
                      style="width: 100%"
                    >
                      <el-option
                        v-for="item in SXOptions"
                        :key="item.dictValue"
                        :label="item.dictLabel"
                        :value="item.dictValue"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="企业地址" prop="address">
                    <el-input
                      placeholder="请输入"
                      v-model="form.address"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="联系人姓名" prop="linkMan">
                    <el-input
                      placeholder="请输入"
                      v-model="form.linkMan"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="联系人电话" prop="phone">
                    <el-input placeholder="请输入" v-model="form.phone">
                      <template slot="append">
                        <el-button type="primary" @click="sendCode">{{
                          munMSG >= 59 ? "发送验证码" : munMSG + "s后重试"
                        }}</el-button>
                      </template>
                    </el-input>
                  </el-form-item>
                  <el-form-item label="验证码" prop="code">
                    <el-input
                      placeholder="请输入"
                      v-model="form.code"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="登录密码" prop="accountPwd">
                    <el-input
                      show-password
                      placeholder="请输入"
                      v-model="form.accountPwd"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="再次输入密码" prop="accountPwdRe">
                    <el-input
                      show-password
                      placeholder="请输入"
                      v-model="form.accountPwdRe"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label-width="260px">
                    <el-button
                      type="primary"
                      @click="onSubmit('form')"
                      class="submit"
                    >
                      立即注册
                    </el-button>
                  </el-form-item>
                </el-form>
              </div>
              <!-- <template v-if="activeIndex == '1'">
                <el-button type="primary" @click="next">下一步</el-button>
              </template>
              <template v-if="activeIndex == '2'">
                <el-button type="primary" @click="next">下一步</el-button>
                <el-button type="primary" @click="prev">上一步</el-button>
              </template>
              <template v-if="activeIndex == '3'">
                <el-button type="primary" @click="prev">上一步</el-button>
              </template> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Layout from "../components/common/Layout";
import Icon from "../components/icon/Icon";
import { baseURL } from "@/utils/request";
import { Exstr } from "@/utils/index";

import Upload from "@/components/upload/index";
import { encrypt } from "@/utils/jsencrypt";
import { register, uniformCreditCode, sendSms } from "@/api/regist";

export default {
  name: "Login",
  components: { Icon, Layout, Upload },
  watch: {
    form: {
      handler(val) {
        if (val.uniformCreditCode !== "") {
          this.getCode(val.uniformCreditCode);
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.form.accountPwd !== "") {
          // this.$refs.form.validateField("accountPwd");
        }
        callback();
      }
    };
    const validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.form.accountPwd) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      pre: baseURL,
      activeIndex: 1,
      SQOptions: [],
      SXOptions: [],
      form: {
        businessLicenseImg: "",
        account: "",
        name: "",
        uniformCreditCode: "",
        foundingTime: "",
        address: "",
        linkMan: "",
        phone: "",
        accountPwd: "",
        accountPwdRe: "",
        icon: "",
        community: "",
        area: "",
        propertyType: "",
        code: "",
      },
      rules: {
        linkPhone: [
          {
            required: true,
            message: "请输入",
            trigger: "change",
          },
        ],
        code: [
          {
            required: true,
            message: "请输入",
            trigger: "change",
          },
        ],
        linkMan: [
          {
            required: true,
            message: "请输入",
            trigger: "change",
          },
        ],
        address: [
          {
            required: true,
            message: "请输入",
            trigger: "change",
          },
        ],
        // account: [
        //   {
        //     required: true,
        //     message: "请输入手机号",
        //     trigger: "blur",
        //   },
        //   { min: 11, message: "请输入11位手机号码", trigger: "blur" },
        // ],
        name: [
          {
            required: true,
            message: "请输入",
            trigger: "change",
          },
        ],
        uniformCreditCode: [
          {
            required: true,
            message: "请输入",
            trigger: "change",
          },
        ],
        foundingTime: [
          {
            required: true,
            message: "请选择时间",
            trigger: "change",
          },
        ],
        community: [
          {
            required: true,
            message: "请选择",
            trigger: "change",
          },
        ],
        area: [
          {
            required: true,
            message: "请输入",
            trigger: "change",
          },
        ],
        propertyType: [
          {
            required: true,
            message: "请选择",
            trigger: "change",
          },
        ],
        accountPwd: [
          { required: true, validator: validatePass, trigger: "blur" },
        ],
        accountPwdRe: [
          { required: true, validator: validatePass2, trigger: "blur" },
        ],
      },
      IStextMSG: true,
      munMSG: 59,
    };
  },
  created() {
    // 所属社区
    this.getDicts("community_type").then((response) => {
      this.SQOptions = response.data;
    });

    // 企业属性
    this.getDicts("property_type").then((response) => {
      this.SXOptions = response.data;
    });
  },
  methods: {
    getCode(code) {
      uniformCreditCode(code).then((res) => {
        if (res.code == 200) {
          console.log(res, "---");
          if (res.data) {
            this.form = {
              ...this.form,
              address: res.data.address,
              linkMan: res.data.linkMan,
              name: res.data.name,
              uniformCreditCode: res.data.uniformCreditCode,
            };
          }
        }
      });
    },
    next() {
      this.activeIndex = this.activeIndex + 1;
    },
    prev() {
      this.activeIndex = this.activeIndex - 1;
    },
    sendCode() {
      // 发送验证码
      if (!this.form.phone && this.form.phone.length == 0) {
        this.$message.error("请先输入手机号");
        return false;
      } else {
        if (this.IStextMSG) {
          sendSms({
            codeType: 2,
            phone: Exstr(this.form.phone),
          }).then((res) => {
            console.log(res, "验证码");
            this.IStextMSG = false;
            this.munMSG = 59;
            this.timer = setInterval(() => {
              this.munMSG -= 1;
              if (this.munMSG <= 0) {
                clearInterval(this.timer);
                this.IStextMSG = true;
                this.munMSG = 59;
              }
            }, 1000);
          });
        }
      }
    },
    handleAvatarSuccess(res, file) {
      console.log(res, file);
      if (res.code == 200) {
        this.form.businessLicenseImg = res.url;
      }
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 20;

      if (!isJPG) {
        this.$message.error("上传图片只能是 JPG |PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    handleAvatarSuccess1(res, file) {
      console.log(res, file);
      if (res.code == 200) {
        this.form.icon = res.url;
      }
    },

    // 图片上传成功的回调
    handleUpload(res) {
      // this.$set(this.form, "businessLicenseImg", res.url);
      console.log(res);
    },
    onSubmit(formName) {
      console.log(33333);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(this.form, "000");
          const { accountPwdRe, ...param } = this.form;
          const init = {
            ...param,
            accountPwd: encrypt(param.accountPwd),
            account: param.phone,
          };
          register(init).then((res) => {
            console.log(res);
            if (res.code == 200) {
              this.$message.success("注册成功");
            }
            this.$router.push({
              path: "/",
            });
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar-uploader {
}

::v-deep .el-input-group__append,
.el-input-group__prepend {
  background: #12a1ec;
  color: #fff !important;
}
::v-deep .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.login-bg {
  width: 100%;
  height: 310px;

  img {
    width: 100%;
    height: 100%;
  }
}

.login-content {
  width: 800px;
  min-height: 576px;
  background: #ffffff;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 205px;
  left: 50%;
  transform: translateX(-50%);
}

.title {
  font-size: 22px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #13a3eb;
  letter-spacing: 1px;
  padding-top: 30px;
}

.upload {
  display: flex;
  justify-content: center;
}

.submit {
  width: 330px;
  height: 44px;
  background: #12a1ec;
  border-radius: 8px;
}
</style>
