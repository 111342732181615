import request from '@/utils/request'

// 通用下载
export function downloadUrl(fileName) {
    return request({
        url: `/open/common/download/${fileName}`,
        method: 'get'
    })
}

// 根据字典类型查询字典数据信息
export function getDicts(dictType) {
    return request({
        url: '/system/dict/data/type/' + dictType,
        method: 'get'
    })
}

export function addFile(param) {
    return request({
        url: '/open/show/file',
        method: 'post',
        data: param
    })
}