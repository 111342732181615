import request from '@/utils/request'

// 登录方法
export function login(param) {
  return request({
    url: '/open/show/company/login',
    method: 'get',
    params: param
  })
}

// 获取用户详细信息
export function getInfo() {
  return request({
    url: '/getInfo',
    method: 'get'
  })
}
// 获取静态资源
export function getLang() {
  return request({
    url: '/system/language/lang-resource',
    method: 'get'
  })
}

// 退出方法
export function logout() {
  return request({
    url: '/logout',
    method: 'post'
  })
}

// 获取验证码
export function getCodeImg() {
  return request({
    url: '/captcha-image',
    method: 'get'
  })
}
