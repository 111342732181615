<template>
  <!-- Start Copy Right Area  -->
  <div class="copyright-area copyright-style-one">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-24 col-md-24 col-sm-24 col-24">
          <div class="copyright-right text-center">
            <p class="copyright-text">© 蜀ICP备2022020484号-1</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- End Copy Right Area  -->
</template>

<script>
export default {
  name: "Copyright",
};
</script>
