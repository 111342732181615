<template>
  <Layout>
    <div>
      <div class="top-img">
        <img src="../assets/img/top.png" alt="顶图" />
      </div>
      <div class="back-home">
        <router-link to="/" exact><span>回到首页</span></router-link>
      </div>
      <div class="tabs-card">
        <el-tabs type="border-card" v-model="activeName" @tab-click="onTab">
          <el-tab-pane name="product" label="项目申报">
            <div class="content-tab">
              <div class="title-item">首页>项目申报</div>
              <div class="flex ptb--20">
                项目分类
                <el-select v-model="type" placeholder="请选择" class="mlr--10">
                  <el-option
                    v-for="item in HYOptions"
                    :key="item.dictValue"
                    :label="item.dictLabel"
                    :value="item.dictValue"
                  >
                  </el-option>
                </el-select>
                <el-button type="primary" class="mlr--10" @click="getData"
                  >搜索</el-button
                >
              </div>
              <el-table
                :data="tableData"
                style="width: 100%"
                :stripe="true"
                border
                :row-class-name="tableRowClassName"
                height="318"
              >
                <el-table-column
                  prop="name"
                  label="项目名称"
                  show-overflow-tooltip
                >
                </el-table-column>
                <el-table-column
                  prop="content"
                  label="项目描述"
                  show-overflow-tooltip
                >
                </el-table-column>
                <el-table-column
                  prop="type"
                  label="项目分类"
                  show-overflow-tooltip
                  :formatter="formatterItemHY"
                >
                </el-table-column>
                <el-table-column label="操作">
                  <template slot-scope="scope">
                    <el-button
                      @click="handleDown(scope.row.files)"
                      icon="el-icon-download"
                      size="mini"
                      type="text"
                      >附件下载
                    </el-button>
                    <!-- <el-button
                      @click="detailXM(scope.row.id)"
                      icon="el-icon-tickets"
                      size="mini"
                      type="text"
                      >详情
                    </el-button> -->
                  </template>
                </el-table-column>
              </el-table>
              <pagination
                :limit.sync="page.pageSize"
                :page.sync="page.pageNum"
                :total="total"
                @pagination="getData"
              />
            </div>
          </el-tab-pane>
          <el-tab-pane name="subsidies" label="我要政策">
            <div class="content-tab">
              <div class="title-item">首页>我要政策</div>
              <div class="flex ptb--20">
                政策分类
                <el-select
                  v-model="policyType"
                  placeholder="请选择"
                  class="mlr--10"
                >
                  <el-option
                    v-for="item in ZCOptions"
                    :key="item.dictValue"
                    :label="item.dictLabel"
                    :value="item.dictValue"
                  >
                  </el-option>
                </el-select>
                <el-button type="primary" class="mlr--10" @click="subsidy"
                  >搜索</el-button
                >
              </div>
              <el-table
                :data="subsidyData"
                style="width: 100%"
                :stripe="true"
                border
                :row-class-name="tableRowClassName"
                height="318"
              >
                <el-table-column
                  prop="name"
                  label="政策名称"
                  show-overflow-tooltip
                >
                </el-table-column>
                <el-table-column
                  prop="level"
                  label="政策级别"
                  show-overflow-tooltip
                  :formatter="formatterItemJB"
                >
                </el-table-column>
                <el-table-column
                  prop="policyType"
                  label="政策类型"
                  show-overflow-tooltip
                  :formatter="formatterItem"
                >
                </el-table-column>
                <el-table-column
                  prop="type"
                  label="行业类型"
                  show-overflow-tooltip
                  :formatter="formatterItemHY"
                >
                </el-table-column>
                <el-table-column
                  prop="content"
                  label="政策描述"
                  show-overflow-tooltip
                >
                </el-table-column>
                <!-- <el-table-column
                  prop="files"
                  label="附件"
                  show-overflow-tooltip
                >
                </el-table-column> -->
                <el-table-column label="操作">
                  <template slot-scope="scope">
                    <el-button
                      @click="handleDown(scope.row.files)"
                      icon="el-icon-download"
                      size="mini"
                      type="text"
                      >附件下载
                    </el-button>
                    <!-- <el-button
                      @click="detailXM(scope.row.id)"
                      icon="el-icon-tickets"
                      size="mini"
                      type="text"
                      >详情
                    </el-button> -->
                  </template>
                </el-table-column>
              </el-table>
              <pagination
                :limit.sync="page.pageSize"
                :page.sync="page.pageNum"
                :total="total"
                @pagination="getData"
              />
            </div>
          </el-tab-pane>
          <el-tab-pane name="investment" label="我要投资"
            ><div class="content-tab">
              <div class="title-item">首页>我要投资</div>
              <el-table
                class="ptb--20"
                :data="investData"
                style="width: 100%"
                :stripe="true"
                border
                :row-class-name="tableRowClassName"
                height="318"
              >
                <el-table-column
                  prop="investName"
                  label="项目名称"
                  show-overflow-tooltip
                >
                </el-table-column>
                <el-table-column
                  prop="investNeed"
                  label="公司简介"
                  show-overflow-tooltip
                >
                </el-table-column>
                <el-table-column
                  prop="investMoney"
                  label="项目金额(万元)"
                  show-overflow-tooltip
                >
                </el-table-column>
                <el-table-column
                  prop="linkName"
                  label="联系人"
                  show-overflow-tooltip
                >
                </el-table-column>
                <el-table-column
                  prop="linkPhone"
                  label="联系电话"
                  show-overflow-tooltip
                >
                </el-table-column>
              </el-table>
              <pagination
                :limit.sync="page.pageSize"
                :page.sync="page.pageNum"
                :total="total"
                @pagination="getData"
              />
            </div>
          </el-tab-pane>
          <el-tab-pane name="loans" label="我要资金"
            ><div class="content-tab">
              <div class="title-item">首页>我要资金</div>
              <el-table
                class="ptb--20"
                :data="loanProductData"
                style="width: 100%"
                :stripe="true"
                border
                :row-class-name="tableRowClassName"
                height="318"
              >
                <el-table-column
                  prop="icon"
                  label="企业图标"
                  show-overflow-tooltip
                >
                </el-table-column>
                <el-table-column
                  prop="blankName"
                  label="贷款银行"
                  show-overflow-tooltip
                >
                </el-table-column>
                <el-table-column
                  prop="content"
                  label="产品描述"
                  show-overflow-tooltip
                >
                </el-table-column>
                <el-table-column
                  prop="linkName"
                  label="联系人"
                  show-overflow-tooltip
                >
                </el-table-column>
                <el-table-column
                  prop="linkPhone"
                  label="联系电话"
                  show-overflow-tooltip
                >
                </el-table-column>
              </el-table>
              <pagination
                :limit.sync="page.pageSize"
                :page.sync="page.pageNum"
                :total="total"
                @pagination="getData"
              />
            </div>
          </el-tab-pane>
          <el-tab-pane name="talent" label="我要人才">
            <div class="relative">
              <div class="content-tab">
                <div class="title-item">首页>我要人才</div>
                <div class="flex ptb--20">
                  招聘岗位
                  <el-input
                    v-model="job"
                    placeholder="请输入"
                    class="mlr--10"
                    style="width: 280px"
                  ></el-input>
                  <el-button type="primary" class="mlr--10" @click="offer"
                    >搜索</el-button
                  >
                </div>
                <el-table
                  :data="offerData"
                  style="width: 100%"
                  :stripe="true"
                  border
                  :row-class-name="tableRowClassName"
                  height="318"
                >
                  <el-table-column
                    prop="companyName"
                    label="招聘企业"
                    show-overflow-tooltip
                  >
                  </el-table-column>
                  <el-table-column
                    prop="job"
                    label="招聘岗位"
                    show-overflow-tooltip
                  >
                  </el-table-column>
                  <el-table-column
                    prop="introduceContent"
                    label="岗位需求"
                    show-overflow-tooltip
                  >
                  </el-table-column>
                  <el-table-column
                    prop="salary"
                    label="薪资范围"
                    show-overflow-tooltip
                  >
                  </el-table-column>
                  <el-table-column
                    prop="linkName"
                    label="联系人"
                    show-overflow-tooltip
                  >
                  </el-table-column>
                  <el-table-column
                    prop="linkPhone"
                    label="电话"
                    show-overflow-tooltip
                  >
                  </el-table-column>
                  <el-table-column
                    prop="endTime"
                    label="招聘截止日期"
                    show-overflow-tooltip
                  >
                  </el-table-column>
                </el-table>
                <pagination
                  :limit.sync="page.pageSize"
                  :page.sync="page.pageNum"
                  :total="total"
                  @pagination="getData"
                />
              </div>
              <div class="btn-step flex" @click="talentClick">
                <span>+</span>
                <span>招聘人才</span>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane name="site" label="我要场地">
            <div class="relative">
              <div class="content-tab">
                <div class="title-item">首页>我要场地</div>
                <div class="flex ptb--20">
                  公司名称
                  <el-input
                    v-model="form1.companyName"
                    placeholder="请输入"
                    class="mlr--10"
                    style="width: 180px"
                  ></el-input>
                  租金标准
                  <el-input
                    v-model="form1.rent"
                    placeholder="请输入"
                    class="mlr--10"
                    style="width: 180px"
                  ></el-input>
                  场地规模
                  <el-input
                    v-model="form1.area"
                    placeholder="请输入"
                    class="mlr--10"
                    style="width: 180px"
                  ></el-input>
                  <el-button type="primary" class="mlr--10" @click="palce"
                    >搜索</el-button
                  >
                </div>
                <el-table
                  :data="placeData"
                  style="width: 100%"
                  :stripe="true"
                  border
                  :row-class-name="tableRowClassName"
                  height="318"
                >
                  <el-table-column
                    prop="companyName"
                    label="企业名称"
                    show-overflow-tooltip
                  >
                  </el-table-column>
                  <el-table-column
                    prop="rent"
                    label="租金标准"
                    show-overflow-tooltip
                  >
                  </el-table-column>
                  <el-table-column
                    prop="area"
                    label="场地规模"
                    show-overflow-tooltip
                  >
                  </el-table-column>
                  <el-table-column
                    prop="content"
                    label="详细描述"
                    show-overflow-tooltip
                  >
                  </el-table-column>
                  <el-table-column
                    prop="linkName"
                    label="联系人"
                    show-overflow-tooltip
                  >
                  </el-table-column>
                  <el-table-column
                    prop="linkPhone"
                    label="联系电话"
                    show-overflow-tooltip
                  >
                  </el-table-column>
                  <!-- <el-table-column prop="type" label="详情"> </el-table-column> -->
                </el-table>
                <pagination
                  :limit.sync="page.pageSize"
                  :page.sync="page.pageNum"
                  :total="total"
                  @pagination="getData"
                />
              </div>
              <div class="btn-step flex" @click="siteClick">
                <span>+</span>
                <span>我要场地</span>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane name="help" label="我要帮助">
            <div class="relative">
              <div class="content-tab">
                <div class="title-item">首页>我要帮助</div>
                <el-table
                  class="ptb--20"
                  :data="helpData"
                  style="width: 100%"
                  :stripe="true"
                  border
                  :row-class-name="tableRowClassName"
                  height="318"
                >
                  <el-table-column
                    prop="company"
                    label="企业名称"
                    show-overflow-tooltip
                  >
                  </el-table-column>
                  <el-table-column
                    prop="title"
                    label="标题"
                    show-overflow-tooltip
                  >
                  </el-table-column>
                  <el-table-column
                    prop="helpType"
                    label="类型"
                    show-overflow-tooltip
                    :formatter="formatterItemBZ"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="area"
                    label="区域"
                    show-overflow-tooltip
                  >
                  </el-table-column>
                  <el-table-column
                    prop="content"
                    label="帮助描述"
                    show-overflow-tooltip
                  >
                  </el-table-column>
                  <el-table-column
                    prop="linkName"
                    label="联系人"
                    show-overflow-tooltip
                  >
                  </el-table-column>
                  <el-table-column
                    prop="linkPhone"
                    label="联系电话"
                    show-overflow-tooltip
                  >
                  </el-table-column>
                </el-table>
                <pagination
                  :limit.sync="page.pageSize"
                  :page.sync="page.pageNum"
                  :total="total"
                  @pagination="getData"
                />
              </div>
              <div class="btn-step flex" @click="helpClick">
                <span>+</span>
                <span>我要帮助</span>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane name="market" label="市场机会">
            <div class="relative">
              <div class="content-tab">
                <div class="title-item">首页>市场机会</div>
                <el-table
                  class="ptb--20"
                  :data="marketData"
                  style="width: 100%"
                  :stripe="true"
                  border
                  :row-class-name="tableRowClassName"
                  height="318"
                >
                  <el-table-column
                    prop="companyName"
                    label="企业名称"
                    show-overflow-tooltip
                  >
                  </el-table-column>
                  <el-table-column
                    prop="name"
                    label="产品名字"
                    show-overflow-tooltip
                  >
                  </el-table-column>
                  <el-table-column
                    prop="content"
                    label="产品介绍"
                    show-overflow-tooltip
                  >
                  </el-table-column>
                  <el-table-column
                    prop="linkName"
                    label="联系人"
                    show-overflow-tooltip
                  >
                  </el-table-column>
                  <el-table-column
                    prop="linkPhone"
                    label="联系电话"
                    show-overflow-tooltip
                  >
                  </el-table-column>
                </el-table>
                <pagination
                  :limit.sync="page.pageSize"
                  :page.sync="page.pageNum"
                  :total="total"
                  @pagination="getData"
                />
              </div>
              <div class="btn-step flex" @click="marketClick">
                <span>+</span>
                <span>发布机会</span>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane name="active" label="企业活动">
            <div class="relative">
              <div class="content-tab">
                <div class="title-item">首页>企业活动</div>
                <el-table
                  class="ptb--20"
                  :data="activeData"
                  style="width: 100%"
                  :stripe="true"
                  border
                  :row-class-name="tableRowClassName"
                  height="318"
                >
                  <el-table-column
                    prop="organizer"
                    label="主办单位"
                    show-overflow-tooltip
                  >
                  </el-table-column>
                  <el-table-column
                    prop="activityTitle"
                    label="活动主题"
                    show-overflow-tooltip
                  >
                  </el-table-column>
                  <el-table-column
                    prop="activityDetail"
                    label="活动内容"
                    show-overflow-tooltip
                  >
                  </el-table-column>
                  <el-table-column
                    prop="coOrganiser"
                    label="协办单位"
                    show-overflow-tooltip
                  >
                  </el-table-column>
                  <el-table-column
                    prop="activityContact"
                    label="联系人"
                    show-overflow-tooltip
                  >
                  </el-table-column>
                  <el-table-column
                    prop="activityTel"
                    label="联系电话"
                    show-overflow-tooltip
                  >
                  </el-table-column>
                  <!-- <el-table-column label="操作">
                    <template slot-scope="scope">
                      <el-button
                        @click="detailHD(scope.row.id)"
                        icon="el-icon-tickets"
                        size="mini"
                        type="text"
                        >详情
                      </el-button>
                    </template>
                  </el-table-column> -->
                </el-table>
                <pagination
                  :limit.sync="page.pageSize"
                  :page.sync="page.pageNum"
                  :total="total"
                  @pagination="getData"
                />
              </div>
              <div class="btn-step flex" @click="activeClick">
                <span>+</span>
                <span>发布活动</span>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane name="feedback" label="意见反馈">
            <div class="relative">
              <div class="content-tab">
                <div class="title-item">首页>意见反馈</div>
                <el-table
                  class="ptb--20"
                  :data="feedbackData"
                  style="width: 100%"
                  :stripe="true"
                  border
                  :row-class-name="tableRowClassName"
                  height="318"
                >
                  <el-table-column
                    prop="companyName"
                    label="企业名称"
                    show-overflow-tooltip
                  >
                  </el-table-column>
                  <el-table-column
                    prop="title"
                    label="标题"
                    show-overflow-tooltip
                  >
                  </el-table-column>
                  <el-table-column
                    prop="content"
                    label="意见反馈内容"
                    show-overflow-tooltip
                  >
                  </el-table-column>
                  <el-table-column
                    prop="createTime"
                    label="反馈时间"
                    show-overflow-tooltip
                  >
                  </el-table-column>
                  <el-table-column
                    prop="linkName"
                    label="联系人"
                    show-overflow-tooltip
                  >
                  </el-table-column>
                  <el-table-column
                    prop="linkPhone"
                    label="联系电话"
                    show-overflow-tooltip
                  >
                  </el-table-column>
                </el-table>
                <pagination
                  :limit.sync="page.pageSize"
                  :page.sync="page.pageNum"
                  :total="total"
                  @pagination="getData"
                />
              </div>
              <div class="btn-step flex" @click="feedBackClick">
                <span>+</span>
                <span>意见反馈</span>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <FeedBackDialog ref="feedBackDialog" @getTableSource="feedback" />
    <HelpDialog ref="helpDialog" @getTableSource="help" />
    <SiteDialog ref="siteDialog" @getTableSource="palce" />
    <TalentDialog ref="talentDialog" @getTableSource="offer" />
    <MarketDialog ref="marketDialog" @getTableSource="market" />
    <ActiveDialog ref="activeDialog" @getTableSource="active" />
    <ActiveDetailDialog ref="activeDetailDialog" @getTableSource="active" />
  </Layout>
</template>

<script>
import Layout from "../components/common/Layout";
import FeedBackDialog from "./feedBack.vue";
import HelpDialog from "./help.vue";
import SiteDialog from "./site.vue";
import TalentDialog from "./talent.vue";
import MarketDialog from "./market.vue";
import ActiveDialog from "./active.vue";
import ActiveDetailDialog from "./detail/active.vue";

import {
  getDeclareInfo,
  getOffers,
  getPlace,
  getSubsidy,
  getInvest,
  getLoanProduct,
  getHelp,
  getFeedback,
  getMarket,
  getActive,
  getCompanyActivity,
} from "@/api/index";

export default {
  name: "ProjectApplication",
  components: {
    Layout,
    FeedBackDialog,
    HelpDialog,
    SiteDialog,
    TalentDialog,
    MarketDialog,
    ActiveDialog,
    ActiveDetailDialog,
  },
  data() {
    return {
      activeName: "product",
      type: "",
      options: [],
      ZCOptions: [], // 政策类型
      HYOptions: [],
      JBOptions: [],
      BZOptions: [],
      tableData: [], // 项目申报
      subsidyData: [], // 我要补贴
      policyType: "",
      offerData: [], // 我要人才
      job: "",

      placeData: [], // 我要场地
      area: "",
      rent: "",
      companyName: "",

      investData: [], // 我要投资

      loanProductData: [], // 我要贷款

      helpData: [], // 我要帮助

      feedbackData: [], // 意见反馈

      activeData: [],
      marketData: [],

      total: 0,
      page: {
        pageSize: 20,
        pageNum: 1,
      },

      form1: {
        companyName: "",
        rent: "",
        area: "",
      },
    };
  },
  mounted() {
    this.activeName = this.$route.params.id;
    this.onTabChange(this.activeName);
  },
  created() {
    this.getData();
    // 政策类型
    this.getDicts("policy_type").then((response) => {
      this.ZCOptions = response.data;
    });
    // 行业类型
    this.getDicts("policy_entry_industry_type").then((response) => {
      this.HYOptions = response.data;
    });
    // 政策级别类型
    this.getDicts("policy_entry_level").then((response) => {
      this.JBOptions = response.data;
    });
    // 政策级别类型
    this.getDicts("help_type").then((response) => {
      this.BZOptions = response.data;
    });
  },
  methods: {
    handleDown(flie) {
      console.log(flie);
      if (flie.length) {
        this.downloadFile(flie[0].fileName);
      }
    },
    detailXM(id) {
      // 项目详情
    },
    detailHD(id) {
      // 企业活动详情
      this.$refs.activeDetailDialog.openDialog({
        type: "add",
        id,
      });
    },
    feedBackClick() {
      this.$refs.feedBackDialog.openDialog();
    },
    helpClick() {
      this.$refs.helpDialog.openDialog();
    },
    activeClick() {
      this.$refs.activeDialog.openDialog();
    },
    siteClick() {
      this.$refs.siteDialog.openDialog();
    },
    talentClick() {
      this.$refs.talentDialog.openDialog();
    },
    marketClick() {
      this.$refs.marketDialog.openDialog();
    },
    // 字典回显
    formatterItem(row, column, cellValue, index) {
      return this.selectDictLabel(this.ZCOptions, cellValue);
    },
    // 字典回显
    formatterItemHY(row, column, cellValue, index) {
      return this.selectDictLabel(this.HYOptions, cellValue);
    },
    // 字典回显
    formatterItemJB(row, column, cellValue, index) {
      return this.selectDictLabel(this.JBOptions, cellValue);
    },
    // 字典回显
    formatterItemBZ(row, column, cellValue, index) {
      return this.selectDictLabel(this.BZOptions, cellValue);
    },
    onTabChange(name) {
      switch (name) {
        case "product":
          this.getData();
          break;
        case "subsidies":
          this.subsidy();
          break;
        case "talent":
          this.offer();
          break;
        case "site":
          this.palce();
          break;
        case "investment":
          this.invest();
          break;
        case "loans":
          this.loanProduct();
          break;
        case "help":
          this.help();
          break;
        case "feedback":
          this.feedback();
          break;
        case "market":
          this.market();
          break;
        case "active":
          this.active();
          break;
        default:
          break;
      }
    },

    onTab({ index }) {
      console.log(index);
      switch (Number(index)) {
        case 0:
          this.getData();
          break;
        case 1:
          this.subsidy();
          break;
        case 2:
          this.invest();
          break;
        case 3:
          this.loanProduct();
          break;
        case 4:
          this.offer();
          break;
        case 5:
          this.palce();
          break;
        case 6:
          this.help();
          break;
        case 7:
          this.market();
        case 8:
          this.active();
        case 9:
          this.feedback();
          break;
        default:
          break;
      }
    },

    // 市场机会
    market(pages) {
      if (pages) {
        const { page, limit } = pages;
        this.page.pageNum = page;
        this.page.pageSize = limit;
      } else {
        this.page.pageNum = 1;
        this.page.pageSize = 20;
      }
      getMarket({ ...this.page }).then((res) => {
        console.log(res, "999");
        if (res.code == 200) {
          this.marketData = res.rows;
          this.total = Number(res.total);
        }
      });
    },

    // 企业活动
    active(pages) {
      if (pages) {
        const { page, limit } = pages;
        this.page.pageNum = page;
        this.page.pageSize = limit;
      } else {
        this.page.pageNum = 1;
        this.page.pageSize = 20;
      }
      getActive({ ...this.page }).then((res) => {
        console.log(res, "999");
        if (res.code == 200) {
          this.activeData = res.rows;
          this.total = Number(res.total);
        }
      });
    },

    // 项目申报
    getData(pages) {
      if (pages) {
        const { page, limit } = pages;
        this.page.pageNum = page;
        this.page.pageSize = limit;
      } else {
        this.page.pageNum = 1;
        this.page.pageSize = 20;
      }
      getDeclareInfo({ ...this.page, type: this.type }).then((res) => {
        console.log(res, "999");
        if (res.code == 200) {
          this.tableData = res.rows;
          this.total = Number(res.total);
        }
      });
    },

    // 我要butie
    subsidy(pages) {
      if (pages) {
        const { page, limit } = pages;
        this.page.pageNum = page;
        this.page.pageSize = limit;
      } else {
        this.page.pageNum = 1;
        this.page.pageSize = 20;
      }
      getSubsidy({ ...this.page, policyType: this.policyType }).then((res) => {
        console.log(res, "999");
        if (res.code == 200) {
          this.subsidyData = res.rows;
          this.total = Number(res.total);
        }
      });
    },

    // 我要人才
    offer(pages) {
      if (pages) {
        const { page, limit } = pages;
        this.page.pageNum = page;
        this.page.pageSize = limit;
      } else {
        this.page.pageNum = 1;
        this.page.pageSize = 20;
      }
      getOffers({ ...this.page, job: this.job }).then((res) => {
        console.log(res, "999");
        if (res.code == 200) {
          this.offerData = res.rows;
          this.total = Number(res.total);
        }
      });
    },

    // 我要场地
    palce(pages) {
      if (pages) {
        const { page, limit } = pages;
        this.page.pageNum = page;
        this.page.pageSize = limit;
      } else {
        this.page.pageNum = 1;
        this.page.pageSize = 20;
      }
      getPlace({ ...this.page, ...this.form1 }).then((res) => {
        console.log(res, "999");
        if (res.code == 200) {
          this.placeData = res.rows;
          this.total = Number(res.total);
        }
      });
    },

    // 我要投资
    invest(pages) {
      if (pages) {
        const { page, limit } = pages;
        this.page.pageNum = page;
        this.page.pageSize = limit;
      } else {
        this.page.pageNum = 1;
        this.page.pageSize = 20;
      }
      getInvest({ ...this.page }).then((res) => {
        console.log(res, "999");
        if (res.code == 200) {
          this.investData = res.rows;
          this.total = Number(res.total);
        }
      });
    },

    // 我要贷款
    loanProduct(pages) {
      if (pages) {
        const { page, limit } = pages;
        this.page.pageNum = page;
        this.page.pageSize = limit;
      } else {
        this.page.pageNum = 1;
        this.page.pageSize = 20;
      }
      getLoanProduct({ ...this.page }).then((res) => {
        console.log(res, "999");
        if (res.code == 200) {
          this.loanProductData = res.rows;
          this.total = Number(res.total);
        }
      });
    },

    // 我要帮助
    help(pages) {
      if (pages) {
        const { page, limit } = pages;
        this.page.pageNum = page;
        this.page.pageSize = limit;
      } else {
        this.page.pageNum = 1;
        this.page.pageSize = 20;
      }
      getHelp({ ...this.page }).then((res) => {
        console.log(res, "999");
        if (res.code == 200) {
          this.helpData = res.rows;
          this.total = Number(res.total);
        }
      });
    },

    // 意见反馈
    feedback(pages) {
      if (pages) {
        const { page, limit } = pages;
        this.page.pageNum = page;
        this.page.pageSize = limit;
      } else {
        this.page.pageNum = 1;
        this.page.pageSize = 20;
      }
      getFeedback({ ...this.page }).then((res) => {
        console.log(res, "999");
        if (res.code == 200) {
          this.feedbackData = res.rows;
          this.total = Number(res.total);
        }
      });
    },

    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 == 0) {
        return "success-row";
      } else {
        return "warning-row";
      }
    },

    homeClick() {
      // 回到首页
    },
  },
};
</script>
<style lang="scss" scoped>
.relative {
  position: relative;
}
.btn-step {
  cursor: pointer;
  z-index: 88;
  position: absolute;
  bottom: 20%;
  right: 18%;
  width: 54px;
  height: 54px;
  background: #13a3eb;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  color: #ffffff;
  line-height: 14px;
  border-radius: 25px;
  text-align: center;
  font-size: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.top-img {
  width: 100%;
  height: 438px;

  img {
    width: 100%;
    height: 100%;
  }
}
.back-home {
  cursor: pointer;
  z-index: 88;
  position: fixed;
  bottom: 20%;
  right: 1%;
  width: 54px;
  height: 54px;
  background: #13a3eb;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  color: #ffffff;
  line-height: 14px;
  border-radius: 25px;
  text-align: center;
  font-size: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  a {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
}
.tabs-card {
  // padding: 0 354px;
  width: 100%;
  box-sizing: border-box;
}

.content-tab {
  width: 1020px;
  margin: 20px auto;
}

.title-item {
  width: 140px;
  height: 28px;
  background: #2577e4;
  color: #fff;
  position: relative;
  margin-left: 20px;
  text-align: center;

  &::before {
    content: "";
    position: absolute;
    width: 8px;
    height: 28px;
    background: #2577e3;
    left: -20px;
    top: 0;
  }

  &::after {
    content: "";
    position: absolute;
    width: 4px;
    height: 28px;
    background: #13a3eb;
    left: -8px;
    top: 0;
  }
}

.mlr--10 {
  margin: 0 10px;
}

.table-head {
  background: #e2fbff;
}

::v-deep .el-tabs--border-card > .el-tabs__header {
  background-color: #12a1ec !important;
  border-bottom: 1px solid #12a1ec;
}
::v-deep .el-tabs__nav {
  padding-left: 194px;
}
::v-deep .el-tabs__item:hover {
  color: #fff;
}

::v-deep .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
  color: #fff;
  background-color: #2577e4 !important;
  border-right-color: #12a1ec;
  border-left-color: #12a1ec;
  width: 152px;
  text-align: center;
}

::v-deep .el-tabs--border-card > .el-tabs__header .el-tabs__item {
  color: #fff;
  border-right-color: #12a1ec;
  border-left-color: #12a1ec;
  width: 152px;
  text-align: center;
}

::v-deep
  .el-tabs--border-card
  > .el-tabs__header
  .el-tabs__item:not(.is-disabled):hover {
  color: #fff;
}

::v-deep .el-tabs--border-card > .el-tabs__header .el-tabs__item {
  color: #fff;
}

::v-deep table {
  margin: 0;
}
::v-deep .el-table thead tr {
  background-color: #e2fbff;
  height: 48px;
}

::v-deep .el-table tbody td {
  border: none;
  height: 48px;
}
::v-deep .el-table tbody .warning-row {
  background-color: #e2fbff !important;
}

::v-deep .el-table tbody .success-row {
  background-color: #fdfaea !important;
}
</style>
